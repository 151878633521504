import {
	GET_BLOGGERS_FAILURE,
	GET_BLOGGERS_REQUEST,
	GET_BLOGGERS_SUCCESS
} from '../../actionTypes'

const initialState = {
	bloggers: [],
	loading: false,
	error: null,
	getNextUrl: '',
	getPrevUrl: '',
}

export default function allBloggers(state = initialState, action) {
	switch (action.type) {
	case GET_BLOGGERS_REQUEST:
		return { ...state, loading: true };
	case GET_BLOGGERS_FAILURE:
		return { ...state, loading: false, error: action.payload };
	case GET_BLOGGERS_SUCCESS:
		return {
			...state,
			loading: false,
			bloggers: action.payload.results,
			getAllPrevUrl: action.payload.links.previous,
			getAllNextUrl: action.payload.links.next,
		};
	default:
		return state;
	}
}
