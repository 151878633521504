/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosArrowDown } from 'react-icons/io';
import { FaThumbsUp } from 'react-icons/fa';
import Card from '../../../../components/Card/card.jsx'
import styles from './Homepage.module.scss';
import {
	getBloggerBlogs, getBloggerDetails, recentlySubmitted, getBlogsNextOrPrev
} from '../../../../store/actions/Blogger/blogger.action'
import Nav from '../../../../components/content-writers/Nav/Nav';
import Sidemenu from '../../../../components/content-writers/Sidemenu/Sidemenu';
import CreatePost from '../../Modals/CreatePost/Create';
import EditPost from '../../Modals/EditPost/Edit';

/* eslint-disable import/extensions */
import blog from '../../../../assets/admin/blog';
import blogue from '../../../../assets/admin/blue-blog';
/* eslint-disable import/extensions */
import createBlog from '../../../../assets/admin/createBlog.png';
import edit from '../../../../assets/admin/edit.png';

const getOnlineStatus = () => (typeof navigator !== 'undefined'
	&& typeof navigator.onLine === 'boolean' ? navigator.onLine : true);

const Homepage = () => {
	const dispatch = useDispatch()
	const history = useHistory()

	const [online, setIsOnline] = useState(getOnlineStatus());
	const [open, setOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [oneBlog, setOneBlog] = useState({});

	const { detail } = useSelector(({ bloggerDetailsReducer }) => ({
		detail: bloggerDetailsReducer.details
	}))

	const {
		myBlogs, next, prev, myBlogloading
	} = useSelector(({ bloggerBlogsReducer }) => ({
		myBlogs: bloggerBlogsReducer.blogs,
		myBlogloading: bloggerBlogsReducer.loading,
		prev: bloggerBlogsReducer.getAllPrevUrl,
		next: bloggerBlogsReducer.getAllNextUrl
	}))

	const { recentBlogs, recentloading } = useSelector(({ bloggerRecentBlogsReducer }) => ({
		recentBlogs: bloggerRecentBlogsReducer.blogs,
		recentloading: bloggerRecentBlogsReducer.loading
	}))

	const openModal = (e) => {
		e.preventDefault()
		setOpen(!open);
	};

	const openEdit = (post) => {
		setEditOpen(!editOpen);
		setOneBlog(post);
	};

	const setOnline = () => setIsOnline(true);
	const setOffline = () => setIsOnline(false);

	// Register the event listeners
	useEffect(() => {
		window.addEventListener('online', setOnline);
		window.addEventListener('offline', setOffline);
		dispatch(getBloggerDetails())
		dispatch(getBloggerBlogs())
		dispatch(recentlySubmitted())

		// cleanup if we unmount
		return () => {
			window.removeEventListener('online', setOnline);
			window.removeEventListener('offline', setOffline);
		};
	}, [dispatch]);

	// <---- getting next page--->
	const nextPosts = () => {
		dispatch(getBlogsNextOrPrev(next))
	};

	// <---- getting prev page--->
	const prevPosts = () => {
		dispatch(getBlogsNextOrPrev(prev))
	};

	// <----converting the timestamp to normal date time---->
	const DATE_OPTIONS = {
		weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
	};

	const jumpToSection = (e) => {
		e.preventDefault()
		window.location.href = '#all-Blogs'
	}

	const viewPublished = (e) => {
		e.preventDefault()
		history.push('/blogger/published')
	}

	const viewRejected = (e) => {
		e.preventDefault()
		history.push('/blogger/rejected')
	}

	const viewDrafts = (e) => {
		e.preventDefault()
		history.push('/blogger/draft')
	}

	return (
		<section className={styles.section}>
			<Sidemenu />
			<Nav />

			{!online ? (
				<div
					style={{
						float: 'right',
						width: '85%',
						height: '70vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						color: 'GrayText'
					}}
				>
					<center>
						<h3 style={{ fontSize: '1.4rem' }}>
							Ooops!!!, seems you are offline...Check your network and try again
						</h3>
					</center>
				</div>
			) : (
				<section className={styles['homepage-container']}>
					<header>
						<div>
							<h2>Content Management</h2>
							<p>Monitor user statistics easily!</p>
						</div>
					</header>

					<div className={styles['action-lists']}>
						<Card
							image={blog}
							title='Blog Posts'
							value={detail.total_blogs}
							text='Total Articles'
							color
							action = {jumpToSection}
						/>
						<Card
							image={blogue}
							title='Accepted'
							value={detail.total_approved_blogs}
							text='Articles'
							action={viewPublished}
						/>
						<Card
							image={blogue}
							title='Rejected'
							value={detail.total_blogs_to_review}
							text='Articles'
							action={viewRejected}
						/>
						<Card
							image={blogue}
							title='Drafts'
							value={detail.total_draft_blogs}
							text='Articles'
							action={viewDrafts}
						/>
						<div
							className={styles.last}
							onClick={(e) => {
								openModal(e);
							}}
						>
							<img src={createBlog} alt="img" />
							<div>
								<h4>Create</h4>
								<h4>Blog Posts</h4>
							</div>
						</div>
					</div>

					<section className={styles['publishing-authors']}>
						<h3 className={styles.pubhead}>Recently Submitted</h3>
						<hr />
						{recentloading ? (
							<div className={styles.loader}>
								<box-icon name='loader-circle' animation='spin' rotate='90' size='lg' ></box-icon>
							</div>
						) : (
							!recentBlogs || recentBlogs.length === 0 ? (
								<article style={{ display: 'block' }}>
									<center>
										<p>
											<b>No published posts as of this time</b>
										</p>
									</center>
								</article>
							) : (
								recentBlogs && recentBlogs.map((myBlog) => (
									<article key={myBlog.id}>
										<div className={styles.postImage}>
											<img src={myBlog.image} alt='Post Image' />
										</div>
										<section className={styles['blogImage-container']}>
											<div>
												<Link
													to={{
														pathname: '/blogger/post',
														blog: {
															post: myBlog
														}
													}}
													id={styles.link}
												>
													<h4>{myBlog.title}</h4>
												</Link>

												<h5 className={styles.aname}>{myBlog.author_name}</h5>

												<p className={styles.cat}>Category: {myBlog.category}</p>
											</div>
											<div className={styles.time}>
												<h6>
													{new Date(myBlog.date_created).toLocaleDateString(
														'en-US',
														DATE_OPTIONS
													)}
												</h6>
												<h6>
													<FaThumbsUp id={styles.like} />
													{myBlog.likes === null ? 0 : myBlog.likes} Likes
												</h6>
											</div>
										</section>

										<h5
											className={
												myBlog.is_draft === true ? (
													styles.brownLabel
												) : myBlog.is_approved === true ? (
													styles.greenLabel
												) : (
													styles.yellowLabel
												)
											}
										>
											{myBlog.is_draft === true ? (
												'Draft'
											) : myBlog.is_approved === true ? (
												'Published'
											) : (
												'In Review'
											)}
										</h5>

										{/* <h5 id={styles.link} onClick={() => {
											openEdit(myBlog);
										}}>
											<img src={edit} alt="img" />
														Edit
										</h5> */}
									</article>
								))
							)
						)}
					</section>

					<section className={styles['all-post']} id="all-Blogs">
						<div className={styles['blog-header']}>
							<h3>My Blog Posts</h3>
							<h4>
									Most Recent
								<IoIosArrowDown />
							</h4>
						</div>
						{myBlogloading ? (
							<div className={styles.loader}>
								<box-icon name='loader-circle' animation='spin' rotate='90' size='lg' ></box-icon>
							</div>
						) : (
							<table>
								<thead>
									<tr>
										<td>Title/Caption</td>
										<td>Comments</td>
										<td>Date Created</td>
										<td>Date Published</td>
										<td>Status</td>
									</tr>
								</thead>
								<tbody>
									{!myBlogs || myBlogs.length === 0 ? (
										<div style={{ display: 'block', paddingTop: 30, borderTop: '.5px solid #dddddd' }}>
											<center>
												<p>
													<b>No published posts as of this time</b>
												</p>
											</center>
										</div>
									) : (
										myBlogs.length > 0
											&& myBlogs.map((post) => (
												<tr key={post.id}>
													<Link
														to={{
															pathname: '/blogger/post',
															blog: {
																post
															}
														}}
														id={styles.link}
													>
														<td>
															<b>{post.title}</b>
														</td>
													</Link>

													<td>{post.comment_count}</td>
													<td>{new Date(post.date_created).toLocaleDateString('en-US', DATE_OPTIONS)}</td>
													<td>
														{post.approve_date ? (
															new Date(post.approve_date).toLocaleDateString('en-US', DATE_OPTIONS)
														) : (
															'Not Applicable'
														)}
													</td>

													<td
														className={
															post.is_draft === true ? (
																styles.brownLabel
															) : post.is_approved === true ? (
																styles.greenLabel
															) : post.in_review === true ? (
																styles.yellowLabel
															) : post.review_needed === true ? (
																styles.redLabel
															) : styles.neutral
														}
													>
														{post.is_draft === true ? (
															'Draft'
														) : post.is_approved === true ? (
															'Published'
														) : post.in_review === true ? (
															'In Review'
														) : post.review_needed === true ? (
															'Rejected'
														) : 'Not Submitted'
														}
													</td>
													{(!post.is_draft && !post.in_review)
														&& (<td className={styles.editbtn} id={styles.link} onClick={() => {
															openEdit(post);
														}}>
															<img src={edit} alt="img" />
															Edit
														</td>)
													}
												</tr>
											))
									)}
								</tbody>

								<p
									onClick={nextPosts}
									className={
										next ? (
											styles['next-button-active']
										) : (
											styles['next-button-inactive']
										)
									}
								>
									Next
								</p>

								<p
									onClick={prevPosts}
									className={
										prev ? (
											styles['back-button-active']
										) : (
											styles['back-button-inactive']
										)
									}
								>
									Back
								</p>
							</table>
						)}
					</section>
				</section>
			)}

			<CreatePost open={open} onClose={openModal} />
			<EditPost open={editOpen} onClose={openEdit} details={oneBlog} />
		</section>
	)
};

export default Homepage;
