import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FaSearch } from 'react-icons/fa'
import styles from './searchInput.module.scss'

const SearchInput = ({ action, info }) => {
	const [searchQuery, setSearchQuery] = useState('')

	const handleChange = (e) => {
		setSearchQuery(e.target.value)
	}

	const handleSearch = () => {
		action()
	}

	return (
		<div className={styles.search}>
			<input
				type='text'
				placeholder={info}
				className={styles.input}
				onChange={handleChange}
				value={searchQuery}
			/>
			<FaSearch className={styles.mirror} size='13' onClick={handleSearch} />
		</div>
	)
}

SearchInput.propTypes = {
	action: PropTypes.func,
	info: PropTypes.string
}

SearchInput.defaultProps = {
	action: (() => {})
}

export default SearchInput
