import {
	APPROVE_REQUEST,
	APPROVE_FAILURE,
	APPROVE_SUCCESS
} from '../../actionTypes'

const initialState = {
	response: null,
	loading: false,
	error: null
}

export default function approveBlogReducer(state = initialState, action) {
	switch (action.type) {
	case APPROVE_REQUEST:
		return { ...state, loading: true };
	case APPROVE_FAILURE:
		return { ...state, loading: false, error: action.payload };
	case APPROVE_SUCCESS:
		return {
			...state,
			loading: false,
		};
	default:
		return state;
	}
}
