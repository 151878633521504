/* eslint-disable dot-notation */
import Axios from 'axios';
import actionCreator from './actionCreator';
import { USER_SIGN_IN_SUCCESS } from '../store/actionTypes';

const setUser = (store: any) => {
	let admin = null;
	let blogger = null;

	const rawUser = localStorage.getItem('admin');
	if (rawUser) {
		admin = JSON.parse(rawUser);
	} else {
		blogger = JSON.parse(localStorage.getItem('user'))
	}

	if (admin || blogger) {
		const adminToken = JSON.parse(localStorage.getItem('adminToken'));
		const authToken = JSON.parse(localStorage.getItem('authToken'));

		if (adminToken) {
			Axios.defaults.headers.common['Authorization'] = `Token ${adminToken}`;
			return store.dispatch(actionCreator(USER_SIGN_IN_SUCCESS, admin))
		}

		if (authToken) {
			Axios.defaults.headers.common['Authorization'] = `Token ${authToken}`;
			return store.dispatch(actionCreator(USER_SIGN_IN_SUCCESS, blogger))
		}
	}
	return null
};
export default setUser;
