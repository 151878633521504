import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { IoIosArrowDown, IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { RiDeleteBin5Line } from 'react-icons/ri';
import style from './Comments.module.scss'

const Comments = ({ data }) => {
	const [toggle, setToggle] = useState(false);
	return (
		<div className={`${style.commentsParent}`}>
			<header>
				<h2>Comments</h2>

				<h3>
					Most Recent <IoIosArrowDown />
				</h3>
			</header>

			<hr />

			<br />

			<section className={`${style.comments}`}>
				{!data || data.length === 0 ? (
					<div className={style.empty}>
						<p>No Comments for this Post yet</p>
					</div>
				) : (
					<table>
						<thead>
							<tr>
								<td>Name</td>
								<td>Email</td>
								<td>Date</td>
								<td>Actions</td>
								<td />
								<td />
							</tr>
						</thead>

						<tbody>
							{data && data.map((comment) => (
								<div key={comment.id}>
									<tr>
										<td>
											<b>{comment.name}</b>
										</td>
										<td>{comment.email}</td>
										<td>{comment.date}</td>
										<td>
											<div>
												<ol className={style.switches}>
													<li>
														<input
															type="checkbox"
															id="1"
															onClick={(e) => (e.target.value)}
														/>
														<label htmlFor="1">
															<span />
														Publish
														</label>
													</li>
												</ol>
											</div>
										</td>
										{/* <td className={style[('flex-td', 'deleteIcon')]}> */}
										<td className={style['flex-td']}>
											<div>
												<RiDeleteBin5Line id={style.icon} size={18} />
											Delete
											</div>
										</td>
										<td className={style['flex-td']} onClick={() => setToggle(!toggle)}>
											<div>
											Open Comment
												{toggle ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
											</div>
										</td>
									</tr>
								</div>(toggle ? (
									<tr className={style.commentTab}>
										<td colSpan="6">
											{comment.body}
										</td>
									</tr>
								) : (
									''
								))(toggle ? (
									<tr className={style.emptyCommentTab}>
										<td />
									</tr>
								) : (
									''
								))
							))}
						</tbody>
					</table>)}
			</section>
		</div>
	);
};

Comments.propTypes = {
	data: PropTypes.array
}

Comments.defaultProps = {
	data: []
}

export default Comments;
