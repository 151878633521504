/* eslint-disable no-nested-ternary */
/* eslint-disable no-lone-blocks */
import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom'
import { Toaster } from 'react-hot-toast';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../Input/input.jsx';
import styles from './Blogin.module.scss';
import { loginUser } from '../../store/actions/blogin.action'

import loaderGif from '../../assets/admin/loading.gif';

const Login = () => {
	const history = useHistory()
	const dispatch = useDispatch()

	const [state, setState] = useState({ username: '', password: '' });
	const [show, setShow] = useState(false);

	const { loading, user } = useSelector(({ userReducer }) => ({
		loading: userReducer.loading,
		user: userReducer.user
	}));

	const setValue = (e) => setState({
		...state,
		[e.target.name]: e.target.value,
	});

	const onClickShow = () => {
		setShow(!show);
	};

	const loginAction = (e) => {
		e.preventDefault()
		dispatch(loginUser(
			{
				...state,
				username: state.username.toLowerCase().trim()
			},
			() => {
				if (user.agent === 'admin') {
					history.push('/blogin/admin')
				}
				if (user.agent === 'blogger') {
					history.push('/blogin/blogger')
				}
				return history.go(0)
			}
		));
	};

	const isAdmin = localStorage.getItem('adminToken')
	const isBlogger = localStorage.getItem('authToken')

	return (
		<main className={styles['login-container']}>
			{isAdmin ? (
				history.push('/blogin/admin')
			) : isBlogger ? (
				history.push('/blogin/blogger')
			) : (
				<div className={styles['center-container']}>
					<Toaster position='top-center' containerStyle={{ fontWeight: 'bold', fontSize: 12 }}/>
					<p>
						<b> Monitor </b> user interactions on <b>SpendWise Blog</b>
					</p>
					<form>
						<Input
							name="username"
							value={state.username}
							action={setValue}
							label="Username"
							margin
							type="text"
						/>
						<Input
							name="password"
							value={state.password}
							action={setValue}
							label="Password"
							margin
							type={show ? 'text' : 'password'}
							showAction={onClickShow}
							isPassword
						/>
						<div className={styles.btnLabel}>
							<button
								onClick={loginAction}
								disabled={!state.username || !state.password || loading}>
								{loading ? 'Loading...' : 'Login' }
							</button>
							{loading ? (
								<img src={loaderGif} alt="img" className={styles.loadingGif} />
							) : (
								<IoIosArrowRoundForward id={styles.btnArrow} />
							)}
						</div>
						<p id={styles.notice}>Don&apos;t have an account ? <Link className='font-bold text-yellow-400' to="register">Signup</Link></p>
					</form>
				</div>
			)}
		</main>
	)
}

export default Login;
