import React from 'react';
import { Route } from 'react-router-dom';

import CategoryPage from '../containers/customer/CategoryPage';
import SinglePostPage from '../containers/customer/SinglePostPage';
import HomePage from '../containers/customer/HomePage';

const routes = () => (
	<>
		<Route path="/" exact component={HomePage} />
		<Route path="/category/:category" component={CategoryPage} />
		<Route path="/post/:postid" component={SinglePostPage}/>
	</>
);

export default routes;
