/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux';
import { IoIosArrowDown } from 'react-icons/io';
import getUnpublished from '../../store/actions/admin/unpublished.action';
import approveBlog from '../../store/actions/admin/approveBlog.action';
import rejectBlog from '../../store/actions/admin/rejectBlog.action';
import { getPublishedBlogs, getRejectedBlogs, getDraftBlogs } from '../../store/actions/Blogger/blogger.action';
import dateOptions from '../../helpers/timeHelper';
import Sidemenu from '../admin/Sidemenu/Sidemenu';
import CreatePost from '../../containers/content-writers/Modals/CreatePost/Create'
import ActionModal from '../ActionModal/actionModal.jsx';
import Nav from '../admin/Nav/Nav';
import BloggerSidemenu from '../content-writers/Sidemenu/Sidemenu';
import BloggerNav from '../content-writers/Nav/Nav';
// eslint-disable-next-line import/extensions
import blogue from '../../assets/admin/blue-blog';
import bck from '../../assets/admin/back.png'
import styles from './cardDetailInfo.module.scss'
import Button from '../Button/button.jsx';

const CardDetailInfo = ({ match }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { cardName, userType } = match.params

	const [open, setOpen] = useState(false);
	const [openRemark, setOpenRemark] = useState(false);
	const [reviewModal, setReviewModal] = useState(false)
	const [oneBlog, setOneBlog] = useState({});
	const [rejectId, setRejectId] = useState(null)
	const [remark, setRemark] = useState('')
	const [approveModal, setApproveModal] = useState(false)

	const { unpublishedLoading, unpublishedPosts } = useSelector(({ unpublished }) => ({
		unpublishedLoading: unpublished.loading,
		unpublishedPosts: unpublished.posts
	}))

	const { aLoading, accepted } = useSelector(({ publishedBlogsReducer }) => ({
		aLoading: publishedBlogsReducer.loading,
		accepted: publishedBlogsReducer.acceptedBlogs
	}))

	const { rLoading, rejected } = useSelector(({ rejectedBlogReducer }) => ({
		rLoading: rejectedBlogReducer.loading,
		rejected: rejectedBlogReducer.rejectedBlogs
	}))

	const { dLoading, drafts } = useSelector(({ getDraftsReducer }) => ({
		dLoading: getDraftsReducer.loading,
		drafts: getDraftsReducer.draftBlogs
	}))

	const { approveLoading } = useSelector(({ approveBlogReducer }) => ({
		approveLoading: approveBlogReducer.loading,
	}))

	const { rejectLoading } = useSelector(({ rejectBlogReducer }) => ({
		rejectLoading: rejectBlogReducer.loading
	}))

	const openModal = (val) => {
		setOpen(!open);
		setOneBlog(val);
	};

	const openReviewModal = () => {
		setReviewModal(!reviewModal)
	}

	const openAdminRemarkModal = () => {
		setOpenRemark(!openRemark)
	}

	const openApproveModal = () => {
		setApproveModal(!approveModal)
	}

	useEffect(() => {
		if (userType === 'blogger') {
			if (cardName === 'published') {
				dispatch(getPublishedBlogs())
			}
			if (cardName === 'rejected') {
				dispatch(getRejectedBlogs())
			}
			if (cardName === 'draft') {
				dispatch(getDraftBlogs())
			}
		}
		if (userType === 'admin' && cardName === 'unpublished') {
			dispatch(getUnpublished())
		}
	}, [cardName, dispatch, userType])

	const approvePost = () => {
		dispatch(approveBlog(rejectId, () => dispatch(getUnpublished())))
	}

	const rejectPost = (reason) => {
		dispatch(rejectBlog({ rejectId, reason }, () => dispatch(getUnpublished())))
	}

	const data = cardName === 'published' ? accepted : cardName === 'rejected' ? rejected : cardName === 'draft' ? drafts : unpublishedPosts

	const title = cardName === 'published' ? 'Published' : cardName === 'rejected' ? 'Needs Review' : cardName === 'draft' ? 'Drafts' : 'Unpublished'

	const loading = cardName === 'published' ? aLoading : cardName === 'rejected' ? rLoading : cardName === 'draft' ? dLoading : unpublishedLoading

	return (
		<div>
			<Toaster containerStyle={{ fontWeight: 'bold', fontSize: 12 }}/>
			{userType === 'blogger' ? (
				<>
					<BloggerSidemenu />
					<BloggerNav />
				</>
			)
				: (<>
					<Sidemenu />
					<Nav />
				</>)}
			<div className={styles.container}>
				<div className={styles.top}>
					<div className={styles.history}>
						<h4 onClick={history.goBack}>
							<img src={bck} alt="img" />
							Back
						</h4>
					</div>
					<div className={styles.pageTitle}>
						<p>
							<img src={blogue} alt="img" />
							{title}</p>
					</div>
				</div>
				<section className={styles.tableContainer}>
					<div className={styles.header}>
						<h4>
							Most Recent
							<IoIosArrowDown />
						</h4>
					</div>

					<article className={styles.table}>
						<div className={`${styles.hgroup} ${cardName === 'unpublished' && styles.hig} ${cardName === 'published' && styles.acc} ${cardName === 'draft' && styles.dra}`}>
							<h5>Title/Caption</h5>
							<h5>Date Created</h5>
							{cardName === 'published'
							&& <h5>Date Published</h5>
							}
							<h5>Author Name</h5>
						</div>
						{loading ? (
							<div className={styles.loader}>
								<box-icon name='loader-circle' animation='spin' rotate='90' size='lg' ></box-icon>
							</div>
						) : (
							!data || data.length === 0 ? (
								<div style={{
									display: 'block',
									paddingTop: 30,
									borderTop: '.5px solid #dddddd'
								}}>
									<center>
										<p>
											<b>No Blog posts here at this time</b>
										</p>
									</center>
								</div>
							) : (
								data.map((post) => (
									<div className={styles['post-list']} key={post.id}>
										<Link to={{
											pathname: `${(userType === 'admin') ? '/admin/post' : '/blogger/post'}`,
											blog: { post }
										}}
										id={styles.link}
										className={styles.justLink}
										>
											<h6>{post.title}</h6>
										</Link>
										<p>{new Date(post.date_created).toLocaleDateString('en-US', dateOptions)}</p>

										{cardName === 'published'
											&& <p>{new Date(post.approve_date).toLocaleDateString('en-US', dateOptions)}</p>
										}

										<p>{post.author_name}</p>

										{userType === 'blogger' ? (
											<>
												<h5 className={
													cardName === 'published' && (styles.greenLabel)}
												>
													{cardName === 'published' && 'Published'}
												</h5>
												{(cardName === 'draft' || cardName === 'rejected')
												 && (
												 <button className={styles.feedback} style={cardName === 'draft' && { width: '10rem' }}
												 		onClick={() => cardName === 'draft' && openModal(post)}
												 >
												 		{cardName === 'draft' && 'Continue Writing'}
												 		{cardName === 'rejected' && 'View Review'}
												 	</button>
												 )}
											</>
										 )
											: (
												post && !post.review_needed
													?											<>
														<Button name='Approve' info action={() => { openApproveModal(); setRejectId(post.id) }}/>
														<Button name='Reject Blog' danger action={() => { openReviewModal(); setRejectId(post.id) }}/>
													</>
											 :												(<>
											 			<div className={styles.reject}>Rejected</div>
														<Button name='View Review' info action={() => { openAdminRemarkModal(); setRemark(post.admins_review_remark) }}/>
													</>
													)
											)}
									</div>
								))
							)
						)}
					</article>
				</section>
			</div>
			<CreatePost open={open} onClose={openModal} details={oneBlog} />
			<ActionModal
				open={reviewModal} onClose={openReviewModal} action={rejectPost} loading={rejectLoading}/>
			<ActionModal open={openRemark} onClose={openAdminRemarkModal} review={remark} />
			<ActionModal
				open={approveModal}
				onClose={openApproveModal} action={approvePost} toApprove loading={approveLoading}/>
		</div>
	)
}

CardDetailInfo.propTypes = {
	match: PropTypes.object,
}

CardDetailInfo.defaultProps = {
	match: {},
}

export default CardDetailInfo
