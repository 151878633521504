/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toaster } from 'react-hot-toast';

// import { IoIosArrowDown, IoIosSearch } from 'react-icons/io';
import { HiUserRemove } from 'react-icons/hi';
import classes from './Users.module.scss';

import Nav from '../../../../components/admin/Nav/Nav';
import ActionModal from '../../../../components/ActionModal/actionModal.jsx';
import Sidemenu from '../../../../components/admin/Sidemenu/Sidemenu';
import { suspendAction, getBloggers } from '../../../../store/actions/admin/adminBlogger.action';
import dateOptions from '../../../../helpers/timeHelper';
// import ModifyUser from '../../Modals/ModifyUser/ModifyUser';

import user from '../../../../assets/admin/add.png';
// import edit from '../../../../assets/admin/edit.png';
// import exp from '../../../../assets/admin/export.png';

const Users = () => {
	const dispatch = useDispatch()
	const [suspendModal, setSuspendModal] = useState(false)
	const [authorId, setAuthorId] = useState(null)
	const [suspensionStatus, setSuspensionStatus] = useState(false)

	const openSuspendModal = () => {
		setSuspendModal(!suspendModal)
	}

	const { bloggerLoading, bloggers } = useSelector(({ allBloggers }) => ({
		bloggerLoading: allBloggers.loading,
		bloggers: allBloggers.bloggers
	}))

	const { suspendLoading } = useSelector(({ suspendBlogger }) => ({
		suspendLoading: suspendBlogger.loading
	}))

	useEffect(() => {
		dispatch(getBloggers())
	}, [dispatch])

	const changeSuspensionStatus = () => {
		dispatch(suspendAction(authorId, () => dispatch(getBloggers())))
	}

	return (
		<div>
			<Toaster containerStyle={{ fontWeight: 'bold', fontSize: 12 }}/>
			<Sidemenu />
			<Nav />

			<section className={classes['users-container']}>
				<header>
					<hgroup>
						<h1>User Management</h1>
						<h4>Monitor professional easily!</h4>
					</hgroup>

					<div className={classes.userParent}>
						<div>
							<img src={user} alt="img" />
						</div>
						<h6>Bloggers Created</h6>
						<h5>{bloggers.length}</h5>
					</div>
				</header>

				{/* <section className={classes['search-bar']}>
					<div>
						<h3>
							Most Recent
							<IoIosArrowDown />
						</h3>

						<label htmlFor="text">
							<IoIosSearch id={`${classes.fnt}`} />
							<input type="text" placeholder="Search" />
						</label>
					</div>

					<div>
						{/* <h4>
							<img src={exp} alt="img" />
							Export
						</h4>
						<h4>Bulk Actions</h4>
					</div>
				</section> */}

				<section className={classes['welcome-users']}>
					<div>
						<h2>
							Welcome to your User <b>Management</b> dashboard where you can view <b>users.</b>
						</h2>
					</div>
					{bloggerLoading ? (
						<div className={classes.loader}>
							<box-icon name='loader-circle' animation='spin' rotate='90' size='lg' ></box-icon>
						</div>
					) : (
						<table>
							<thead>
								<tr>
									<td />
									<td>Name</td>
									<td>Email</td>
									<td>Date Added</td>
									<td>Actions</td>
								</tr>
							</thead>
							<tbody>
								{!bloggers || bloggers.length === 0 ? (
									<p className={classes.none}>
										<b>No user has signed up yet</b>
									</p>
								) : (
									bloggers && bloggers.map((blogger) => (
										<tr key={blogger.id}>
											<td>
												<input type="checkbox" />
											</td>
											<td>{blogger.username}</td>
											<td>{blogger.email}</td>
											<td>{new Date(blogger.created).toLocaleDateString('en-US', dateOptions)}</td>
											{/* <td className={classes['flex-td']}>
										<div
											onClick={(e) => {
												openModifyModal(e);
											}}
										>
											<img src={edit} alt="img" />
											Modify
										</div>
									</td> */}
											<td className={classes['flex-td']} onClick={() => { openSuspendModal(); setAuthorId(blogger.id); setSuspensionStatus(blogger.suspended) }}>
												{blogger.suspended ? (
													<p>
														Lift suspension
													</p>
												)
													: (
														<p>
															<HiUserRemove className={classes.suspendFont} />
												Suspend
														</p>)}
											</td>
										</tr>
									))
								)}
							</tbody>
						</table>)}
				</section>

				{/* <div className={classes.nxtBack}>
					<h5>Previous</h5>
					<p className={classes.current}>1</p>
					<p>2</p>
					<p>3</p>
					<p>4</p>
					<p>5</p>
					<h5>Next</h5>
				</div> */}
			</section>

			{/* <ModifyUser open={openUserModal} onClose={openModifyModal} /> */}
			<ActionModal
				open={suspendModal}
				onClose={openSuspendModal}
				action={changeSuspensionStatus} suspend suspensionStatus={suspensionStatus}
				loading={suspendLoading} />
		</div>
	);
};

export default Users;
