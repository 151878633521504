import {
	USER_REGISTER_FAILURE,
	USER_REGISTER_REQUEST,
	USER_REGISTER_SUCCESS
} from '../actionTypes'

const initialState = {
	response: null,
	loading: false,
	error: null
}

export default function registerBlogger(state = initialState, action) {
	switch (action.type) {
	case USER_REGISTER_REQUEST:
		return { ...state, loading: true };
	case USER_REGISTER_FAILURE:
		return { ...state, loading: false, error: action.payload.response.data };
	case USER_REGISTER_SUCCESS:
		return {
			...state,
			loading: false,
			response: action.payload
		};
	default:
		return state;
	}
}
