import {
	SUMMARY_REQUEST,
	SUMMARY_FAILURE,
	SUMMARY_SUCCESS
} from '../../actionTypes'

const initialState = {
	summary: {},
	loading: false,
	error: null
}

export default function summaryReducer(state = initialState, action) {
	switch (action.type) {
	case SUMMARY_REQUEST:
		return { ...state, loading: true };
	case SUMMARY_FAILURE:
		return { ...state, loading: false, error: action.payload };
	case SUMMARY_SUCCESS:
		return { ...state, loading: false, summary: action.payload };
	default:
		return state;
	}
}
