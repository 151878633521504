/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import toast, { Toaster } from 'react-hot-toast';

// import axios for fetching apis
import Axios from 'axios';

import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import classes from './Edit.module.css';

import closeIcon from '../../../../assets/admin/close.png';

const Edit = ({ onClose, open, details }) => {
	const [title, setTitle] = useState('');
	const [image, setImage] = useState(null);
	const [category, setCategory] = useState('');
	const [preview, setPreview] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [updatedMessage, setUpdatedMessage] = useState('');
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty(),)
	const [convertedContent, setConvertedContent] = useState(null);

	useEffect(() => {
		if (details?.body) {
			setEditorState(EditorState.createWithContent(
				convertFromRaw(JSON.parse(details.body))
			))
		}
	}, [details])

	const convertForStorage = () => {
		const contentState = editorState.getCurrentContent()
		const note = { content: convertToRaw(contentState) }
		note.content = JSON.stringify(note.content)
		setConvertedContent(note.content);
	}

	const handleEditorChange = (state) => {
		setEditorState(state);
		convertForStorage();
	}

	// On file select (from the pop up)
	const onFileChange = (e) => {
		if (e.target.files && e.target.files[0]) {
			setImage(e.target.files[0]);
			setPreview(URL.createObjectURL(e.target.files[0]));
		}
	};

	// creating a blog post
	const updateBlogPost = (e) => {
		e.preventDefault();
		setIsSubmitting(true);

		// get user token from saved storage
		const userToken = JSON.parse(localStorage.getItem('authToken'));

		// the updated endpoint is only expecting the body
		const formData = new FormData();
		formData.set('title', title);
		formData.append('body', convertedContent);
		formData.append('category', category);
		if (image) {
			formData.append('image', image, image.name)
		}

		const config = {
			headers: {
				Authorization: `Token ${userToken}`
			}
		};

		const buildForm = {
			title: formData.get('title'),
			body: formData.get('body'),
			category: formData.get('category'),
			image: formData.get('image'),
			is_draft: formData.get('is_draft')
		}

		const updateFormData = {
			blog_id: details.id,
			update_content: buildForm
		}

		Axios.post('https://www.spendwise.ng/api/blog/update_blogpost/', updateFormData, config)
		// eslint-disable-next-line no-unused-vars
			.then((res) => {
				setIsSubmitting(false);
				toast.success(res.data.message);
				setTimeout(() => {
					window.location.reload();
				}, 2000);
			})
			.catch((error) => {
				setIsSubmitting(false);
				toast.error(error.message);
			});
	};

	const onclose = (e) => {
		setImage(null)
		setTitle('')
		setCategory('')
		setIsSubmitting(false)
		setPreview(null)
		setUpdatedMessage('')
		setEditorState(() => EditorState.createEmpty(),)
		setConvertedContent('')
		onClose(e);
	};

	if (!open) {
		return null;
	}

	return (
		<div className={classes['edit-container']}>
			<Toaster containerStyle={{ fontWeight: 'bold', fontSize: 12 }}/>
			<div className={classes['edit-content']}>
				<img src={closeIcon} alt="img" id={classes.closeIcon} onClick={onclose} />

				<center>
					<h1>Edit Blog Post</h1>
					<p>All contents edited and
						published are made available
						to customers.
					</p>
				</center>

				<div className={classes['img-container']}>
					{preview ? (
						<img src={preview} alt="img" />
					) : (
						<img src={details.image} alt="img" />
					)}
				</div>

				<form action="" id='formElem'>
					<label htmlFor="title">
						Title
						<input
							type="text"
							disabled
							name="title"
							defaultValue={details.title}
							onChange={(e) => setTitle(e.target.value)}
							placeholder="Enter Incentive Title"
						/>
					</label>

					<label htmlFor="img">
						Upload Header Image
						<input
							type="file"
							name="image"
							accept="image/jpg, image/jpeg, image/png"
							disabled
							onChange={onFileChange}
						/>
					</label>

					<label htmlFor="category" className={classes['input-category']}>
						Select Category
						<select
							disabled
							name="category"
							id=""
							defaultValue={details.category}
							onChange={(e) => setCategory(e.target.value)}
							style={{
								border: 'revert'
							}}
						>
							<option value="" />
							<option value="save">Save</option>
							<option value="manage">Manage</option>
							<option value="keep">Keep</option>
							<option value="make">Make</option>
							<option value="debt">Debt</option>
							<option value="protect">Protect</option>
							<option value="grow">Grow</option>
						</select>
					</label>

					<label>
						<Editor
							editorState={editorState}
							onEditorStateChange={handleEditorChange}
							wrapperClassName={classes['wrapper-class']}
							editorClassName={classes['editor-class']}
							toolbarClassName={classes['toolbar-class']}
						/>
					</label>
				</form>

				{updatedMessage ? <center style={{ fontSize: 14, color: 'red' }}>{updatedMessage}</center> : ''}
				<div className={classes.btnParent}>
					<button onClick={onclose}>Cancel</button>
					<button
						onClick={updateBlogPost}
						disabled={(convertedContent === '<p></p>')}
					>
						{isSubmitting ? (
							<box-icon name='loader-circle' animation='spin' rotate='90' size='sm' color='white'></box-icon>
						) : 'Submit & Publish' }
					</button>
				</div>
			</div>
		</div>
	);
};

Edit.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool,
	details: PropTypes.object
}

export default Edit;
