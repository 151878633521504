import {
	GET_UNAPPROVED_FAILURE,
	GET_UNAPPROVED_LOADING,
	GET_UNAPPROVED_SUCCESS
} from '../../actionTypes'

const initialState = {
	posts: [],
	loading: false,
	error: null
}

export default function unpublished(state = initialState, action) {
	switch (action.type) {
	case GET_UNAPPROVED_LOADING:
		return { ...state, loading: true };
	case GET_UNAPPROVED_FAILURE:
		return { ...state, loading: false, error: action.payload };
	case GET_UNAPPROVED_SUCCESS:
		return {
			...state,
			loading: false,
			posts: action.payload.blog_data
		};
	default:
		return state;
	}
}
