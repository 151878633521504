import {
	GET_BLOGGER_DETAILS_FAILURE,
	GET_BLOGGER_DETAILS_LOADING,
	GET_BLOGGER_DETAILS_SUCCESS
} from '../../actionTypes'

const initialState = {
	details: {},
	loading: false,
	error: null
}

export default function bloggerDetailsReducer(state = initialState, action) {
	switch (action.type) {
	case GET_BLOGGER_DETAILS_LOADING:
		return { ...state, loading: true };
	case GET_BLOGGER_DETAILS_FAILURE:
		return { ...state, loading: false, error: action.payload };
	case GET_BLOGGER_DETAILS_SUCCESS:
		return { ...state, loading: false, details: action.payload.blogger_details };
	default:
		return state;
	}
}
