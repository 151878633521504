import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { MdMail } from 'react-icons/md';
import { FaUser, FaSignOutAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux'
import { logout } from '../../../store/actions/blogin.action'
import classes from './Sidemenu.module.css';
import logo from '../../../assets/admin/logo-bg-white.png';

const Sidemenu = () => {
	const history = useHistory()
	const dispatch = useDispatch()

	const logoutAction = (e) => {
		e.preventDefault()
		dispatch(logout())
		history.push('/blogin')
		return history.go(0)
	}

	return (
		<div className={classes['sidemenu-container']}>
			<img onClick={() => { history.push('/'); history.go(0) }} src={logo} alt="logo" id={classes['side-logo']} style={{ cursor: 'pointer' }}/>

			<ul className={classes['sidemenu-list']}>
				<NavLink exact to="/blogin/admin" className={classes.div} activeClassName={classes.active}>
					<MdMail className={classes.listIcon} />
					<li>Content</li>
				</NavLink>

				<NavLink exact to="/admin/users" className={classes.div} activeClassName={classes.active}>
					<FaUser className={classes.listIcon} />
					<li>Users</li>
				</NavLink>
			</ul>

			<footer>
				<ul onClick={logoutAction}>
					<FaSignOutAlt className={`${classes.listIcon} ${classes.footIcon}`} />
					<li>Sign out</li>
				</ul>
			</footer>
		</div>
	)
};

export default Sidemenu;
