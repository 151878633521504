/* eslint-disable camelcase */
import axios from 'axios';
import * as actionTypes from '../../../actionTypes';

import axiosInstance from '../../../../utils/axios';

export const getAllByCatStart = () => ({
	type: actionTypes.GET_ALL_BY_CAT_START,
});

export const getAllByCatSuccess = (data) => {
	const {
		results, links, total_pages, page_number
	} = data;

	return {
		type: actionTypes.GET_ALL_BY_CAT_SUCCESS,
		results,
		message: data.message,
		previous: links.previous,
		next: links.next,
		total_pages,
		page_number,
	};
};

export const getAllByCatFailed = (error) => ({
	type: actionTypes.GET_ALL_BY_CAT_FAILED,
	error,
});

export const getAllByCat = (data) => (dispatch) => {
	dispatch(getAllByCatStart());
	axiosInstance
		.post('/blog/get_by_category/', data)
		.then((response) => {
			dispatch(getAllByCatSuccess(response.data));
		})
		.catch((error) => {
			if (error.response) {
				dispatch(getAllByCatFailed(error.response.data));
			} else if (error.message) {
				dispatch(
					getAllByCatFailed({
						message: error.message,
					})
				);
			} else {
				dispatch(
					getAllByCatFailed({
						message: 'Error retrieving all budgets',
					})
				);
			}
		});
};

export const getAllByCatNextorPrev = (url, data) => (dispatch) => {
	dispatch(getAllByCatStart());

	axios
		.post(url, data)
		.then((response) => {
			dispatch(getAllByCatSuccess(response.data));
		})
		.catch((error) => {
			if (error.response) {
				dispatch(getAllByCatFailed(error.response.data));
			} else if (error.message) {
				dispatch(
					getAllByCatFailed({
						message: error.message,
					})
				);
			} else {
				dispatch(
					getAllByCatFailed({
						message: 'Error retrieving all budgets',
					})
				);
			}
		});
};
