import * as actionTypes from '../../actionTypes';

const initialState = {
	error: undefined,
	loading: false,
};

const commentReducer = (state = initialState, action) => {
	switch (action.type) {
	//
	case actionTypes.ADD_COMMENT_START:
		return {
			...state,
			error: undefined,
			loading: true,
		};
	case actionTypes.ADD_COMMENT_SUCCESS:
		return {
			...state,
			loading: false,
		};
	case actionTypes.ADD_COMMENT_FAILED:
		return {
			...state,
			loading: false,
			error: action.error,
		};
	default:
		return state;
	}
};

export default commentReducer
