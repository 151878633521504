import React from 'react'
import PropTypes from 'prop-types'
import style from './button.module.scss'

const Button = ({
	name, action, danger, warning, info, styles, disAllow
}) => (
	<button
		disabled={disAllow && true}
		className={`
				${style.button}
				${danger && style.danger}
				${warning && style.warning}
				${info && style.info}
			`}
		style={styles}
		onClick={action}
	>
		{name}
	</button>
)

Button.propTypes = {
	name: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object
	]),
	action: PropTypes.func,
	danger: PropTypes.bool,
	warning: PropTypes.bool,
	info: PropTypes.bool,
	styles: PropTypes.object,
	disAllow: PropTypes.bool
}

export default Button
