import React from 'react';

import { Link } from 'react-router-dom';
import moment from 'moment';

import classes from './articlebox.module.css';

const articleBox = (props) => {
	const { data } = props;

	const formatDate = (date) => {
		const now = moment(new Date()); // todays date
		const end = moment(date); // another date
		const duration = moment.duration(now.diff(end));
		const days = duration.asDays();
		const hours = duration.asHours();

		if (days < 1) {
			return `${hours.toFixed(0)} hours ago`;
		}
		return `${days.toFixed(0)} days ago`;

		// return moment(date).format("MMMM Do, YYYY")
	};

	const postImageUrl = data && data.image;

	return (
		<Link
			to={`/post/${data.id}`}
			style={{
				textDecoration: 'none',
				color: 'unset',
			}}
		>
			<div className={classes.container}>
				<div
					style={{
						height: 190,
						backgroundColor: 'grey',
						borderTopLeftRadius: 10,
						borderTopRightRadius: 10,
					}}
				>
					<img
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'fit',
							borderTopLeftRadius: 10,
							borderTopRightRadius: 10,
						}}
						src={postImageUrl}
					/>
				</div>
				<div className={classes['details-wrapper']}>
					<div className={classes.tag}>
						{data && data.category}
					</div>
					<div className={classes.excerpt}>
						{data && data.title}
					</div>
					<div
						style={{
							display: 'flex',
							gap: 10,
						}}
						className={classes.misc}
					>
						<span>{formatDate(data.approve_date)}</span>
						<span>{data && data.views} views</span>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default articleBox;
