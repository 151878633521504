import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { ImEye, ImEyeBlocked } from 'react-icons/im'
import style from './input.module.scss'

const Input = ({
	name,
	value,
	action,
	label,
	margin,
	type,
	showAction,
	isPassword
}) => {
	const [show, setShow] = useState(false)

	const showPassword = (e) => {
		e.preventDefault()
		showAction()
		setShow(!show)
	}

	return (
		<div className={`${style.input} ${margin && style.margin}`}>
			{/* {isPassword && <label htmlFor="password"> Password </label>} */}
			<label htmlFor={name}> {label} </label>
			{/* {label
      && <label htmlFor={name} className={isPassword && style.innerPasswrd}>
      	{!isPassword && label} */}
			<input
				name={name}
				type={type}
				onChange={action}
				value={value}
			/>
			{isPassword && <span onClick={showPassword}> {show ? <ImEye /> : <ImEyeBlocked />} </span>}
			{/* </label>} */}
		</div>
	)
}

Input.propTypes = {
	name: PropTypes.string,
	value: PropTypes.string,
	label: PropTypes.string,
	action: PropTypes.func,
	margin: PropTypes.bool,
	type: PropTypes.oneOf(['text', 'password', 'email']),
	isPassword: PropTypes.bool,
	showAction: PropTypes.func
}

Input.defaultProps = {
	name: null,
	value: null,
	label: null,
	action: () => { },
	margin: false,
	type: 'text',
	isPassword: false,
	showAction: () => { }
}

export default Input;
