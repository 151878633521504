import Axios from 'axios'
import toast from 'react-hot-toast'
import actionCreator from '../../../utils/actionCreator'
import {
	APPROVE_FAILURE,
	APPROVE_REQUEST,
	APPROVE_SUCCESS
} from '../../actionTypes'

const approveBlog = (id, action) => async (dispatch) => {
	dispatch(actionCreator(APPROVE_REQUEST))
	try {
		const { data: res } = await Axios.post('https://www.spendwise.ng/api/blog/approve_post/', {
			post_id: id
		})
		dispatch(actionCreator(APPROVE_SUCCESS, res))
		toast.success('Blog approved successfully')
		action()
	} catch (err) {
		dispatch(actionCreator(APPROVE_FAILURE, 'Unable to approve the blogPost at this time'));
		toast.error('Unable to approve the blogPost at this time')
	}
}

export default approveBlog
