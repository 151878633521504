import React from 'react';
import PropTypes from 'prop-types'
import { IoIosArrowForward } from 'react-icons/io';
import styles from './card.module.scss'

const Card = ({
	image,
	title,
	value,
	text,
	action,
	color,
}) => (
	<div className={`${styles.card} ${color && styles.backColor}`}>
		<div className={`${styles.img} ${color && styles.bc}`}>
			<img src={image} alt="img" />
		</div>
		<h4 className={`${styles.title} ${color && styles.btitle}`}>{title}</h4>
		<h3 className={`${styles.value} ${color && styles.bval}`}>{value}</h3>
		<h5 className={`${styles.txt} ${color && styles.ctxt}`}>{text}</h5>
		<h6 onClick={action} className={color ? styles.diff : ''}>
				View All
			<IoIosArrowForward />
		</h6>
	</div>
)

Card.propTypes = {
	image: PropTypes.string,
	title: PropTypes.string,
	value: PropTypes.number,
	text: PropTypes.string,
	action: PropTypes.func,
	color: PropTypes.bool
}

Card.defaultProps = {
	image: null,
	title: null,
	value: 0,
	text: null,
	action: () => { },
	actionColor: false,
	color: false
}

export default Card;
