import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom'
import { Toaster } from 'react-hot-toast';
import { IoIosArrowRoundForward } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../Input/input.jsx';
import registerUser from '../../store/actions/register.action';
import ActionModal from '../ActionModal/actionModal.jsx';

import loaderGif from '../../assets/admin/loading.gif';

const Signup = () => {
	const history = useHistory()
	const dispatch = useDispatch()

	const [state, setState] = useState({
		username: '', password: '', confirmPassword: '', email: '', phone: '', refCode: ''
	});
	const [show, setShow] = useState(false);
	const [successModal, setSuccessModal] = useState(false)

	const { loading } = useSelector(({ registerBlogger }) => ({
		loading: registerBlogger.loading,
	}));

	const setValue = (e) => setState({
		...state,
		[e.target.name]: e.target.value,
	});

	const onClickShow = () => {
		setShow(!show);
	};

	const openSuccessModal = () => {
		setSuccessModal(!successModal)
	}

	const registerAction = (e) => {
		e.preventDefault()
		dispatch(registerUser(
			{
				...state,
				username: state.username.toLowerCase().trim(),
				email: state.email.toLowerCase().trim()
			},
			() => openSuccessModal()
		));
	};

	return (
		<main className='font-sans bg-blueOverlay bg-auth-image bg-center bg-no-repeat bg-blend-soft-light w-full h-screen text-white flex justify-center items-center md:h-full lg:h-screen'>
			<Toaster position='top-center' containerStyle={{ fontWeight: 'bold', fontSize: 12 }}/>
			<div className='flex flex-col mx-auto my-0 w-4/5 md:justify-between md:p-10'>
				<p className='invisible md:visible md:text-3xl md:mb-4 md:mx-auto'>
					<b> Join </b> the <b>SpendWise Blog</b>
				</p>
				<form className='mb-10 md:w-4/5 md:mx-auto lg:w-2/5'>
					<Input
						name="username"
						value={state.username}
						action={setValue}
						label="Username"
						margin
						type="text"
					/>
					<Input
						name="email"
						value={state.email}
						action={setValue}
						label="Email"
						margin
						type='email'
					/>
					<Input
						name="phone"
						value={state.phone}
						action={setValue}
						label="Phone number"
						margin
						type='text'
					/>
					<Input
						name="password"
						value={state.password}
						action={setValue}
						label="Password"
						margin
						type={show ? 'text' : 'password'}
						showAction={onClickShow}
						isPassword
					/>
					<Input
						name="confirmPassword"
						value={state.confirmPassword}
						action={setValue}
						label="Confirm password"
						margin
						type='password'
					/>
					<Input
						name="refCode"
						value={state.refCode}
						action={setValue}
						label="Referral Code (optional)"
						margin
						type='text'
					/>
					<div className='flex items-center h-12'>
						<button
							onClick={registerAction}
							className='bg-buttonBck border border-solid border-buttonBck cursor-pointer w-4/5 rounded-none rounded-bl-md rounded-tl-md text-sm'
							disabled={
								!state.username
								|| !state.password
								|| !state.confirmPassword
								|| !state.phone
								|| !state.email
								|| loading
							}> {loading ? 'Loading...' : 'Sign Up'} </button>
						{loading ? (
							<img src={loaderGif} alt="img" className='w-1/5 h-10 rounded-br-md rounded-tr-md'/>
						) : (
							<IoIosArrowRoundForward className='bg-btnArrow text-black h-10 w-1/5 rounded-br-md rounded-tr-md'/>
						)}
					</div>
					<p className='mt-1 text-sm md:text-base'>Already have an account ? <Link className='font-bold text-yellow-400' to="blogin">Login</Link></p>
				</form>
			</div>
			<ActionModal
				open={successModal} onClose={openSuccessModal} action={() => { history.push('/'); history.go(0) }} signupSuccess />
		</main>
	)
}

export default Signup;
