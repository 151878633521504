import Axios from 'axios'
import actionCreator from '../../../utils/actionCreator'
import {
	SUMMARY_FAILURE,
	SUMMARY_REQUEST,
	SUMMARY_SUCCESS
} from '../../actionTypes'

const getSummary = () => async (dispatch) => {
	dispatch(actionCreator(SUMMARY_REQUEST))
	try {
		const { data: res } = await Axios.get('https://www.spendwise.ng/api/blog/blog_admin_summary/')
		dispatch(actionCreator(SUMMARY_SUCCESS, res))
	} catch (err) {
		dispatch(actionCreator(SUMMARY_FAILURE, 'Unable to fetch summary'));
	}
}

export default getSummary
