import Axios from 'axios'
import actionCreator from '../../../utils/actionCreator'
import {
	GET_BLOGGER_DETAILS_FAILURE,
	GET_BLOGGER_DETAILS_LOADING,
	GET_BLOGGER_DETAILS_SUCCESS,
	GET_BLOGGER_POSTS_FAILURE,
	GET_BLOGGER_POSTS_LOADING,
	GET_BLOGGER_POSTS_SUCCESS,
	RECENTLY_SUBMMITED_FAILURE,
	RECENTLY_SUBMMITED_LOADING,
	RECENTLY_SUBMMITED_SUCCESS,
	GET_PUBLISHED_FAILURE,
	GET_PUBLISHED_LOADING,
	GET_PUBLISHED_SUCCESS,
	GET_DRAFT_FAILURE,
	GET_DRAFT_LOADING,
	GET_DRAFT_SUCCESS,
	GET_REJECTED_FAILURE,
	GET_REJECTED_LOADING,
	GET_REJECTED_SUCCESS
} from '../../actionTypes'

export const getBloggerDetails = () => async (dispatch) => {
	dispatch(actionCreator(GET_BLOGGER_DETAILS_LOADING))
	try {
		const { data: res } = await Axios.get('https://www.spendwise.ng/api/blog/get_blogger_details/')
		dispatch(actionCreator(GET_BLOGGER_DETAILS_SUCCESS, res))
	} catch (err) {
		dispatch(actionCreator(GET_BLOGGER_DETAILS_FAILURE, err.message));
	}
}

export const getBloggerBlogs = () => async (dispatch) => {
	dispatch(actionCreator(GET_BLOGGER_POSTS_LOADING))
	try {
		const { data: res } = await Axios.get('https://www.spendwise.ng/api/blog/blogger_blogs/')
		dispatch(actionCreator(GET_BLOGGER_POSTS_SUCCESS, res))
	} catch (err) {
		dispatch(actionCreator(GET_BLOGGER_POSTS_FAILURE, err.message))
	}
}

export const recentlySubmitted = () => async (dispatch) => {
	dispatch(actionCreator(RECENTLY_SUBMMITED_LOADING))
	try {
		const { data: res } = await Axios.get('https://www.spendwise.ng/api/blog/get_f4_blogger_blogs/')
		dispatch(actionCreator(RECENTLY_SUBMMITED_SUCCESS, res))
	} catch (err) {
		dispatch(actionCreator(RECENTLY_SUBMMITED_FAILURE, 'Unable to retrieve latest blogs at this time'))
	}
}

export const getPublishedBlogs = () => async (dispatch) => {
	dispatch(actionCreator(GET_PUBLISHED_LOADING))
	try {
		const { data: res } = await Axios.get('https://www.spendwise.ng/api/blog/get_all_published_blogs')
		dispatch(actionCreator(GET_PUBLISHED_SUCCESS, res))
	} catch (err) {
		dispatch(actionCreator(GET_PUBLISHED_FAILURE, err.message))
	}
}

export const getRejectedBlogs = () => async (dispatch) => {
	dispatch(actionCreator(GET_REJECTED_LOADING))
	try {
		const { data: res } = await Axios.get('https://www.spendwise.ng/api/blog/get_rejected_blog')
		dispatch(actionCreator(GET_REJECTED_SUCCESS, res))
	} catch (err) {
		dispatch(actionCreator(GET_REJECTED_FAILURE, err.message))
	}
}

export const getDraftBlogs = () => async (dispatch) => {
	dispatch(actionCreator(GET_DRAFT_LOADING))
	try {
		const { data: res } = await Axios.get('https://www.spendwise.ng/api/blog/drafts/')
		dispatch(actionCreator(GET_DRAFT_SUCCESS, res))
	} catch (err) {
		dispatch(actionCreator(GET_DRAFT_FAILURE, err.message))
	}
}

// To-do: Add next pages for the accepted, rejected and drafts too

export const getBlogsNextOrPrev = (url) => async (dispatch) => {
	dispatch(actionCreator(GET_BLOGGER_POSTS_LOADING))
	try {
		const { data: res } = await Axios.get(url)
		dispatch(actionCreator(GET_BLOGGER_POSTS_SUCCESS, res))
	} catch (err) {
		dispatch(actionCreator(GET_BLOGGER_POSTS_FAILURE, 'Unable to retrieve blogs at this time'))
	}
}
