import Axios from 'axios'
import toast from 'react-hot-toast'
import actionCreator from '../../../utils/actionCreator'
import {
	DELETE_POSTS_FAILURE,
	DELETE_POSTS_LOADING,
	DELETE_POSTS_SUCCESS
} from '../../actionTypes'

const deleteBlog = (id, action) => async (dispatch) => {
	dispatch(actionCreator(DELETE_POSTS_LOADING))
	try {
		const { data: res } = await Axios.post('https://www.spendwise.ng/api/blog/delete/', {
			blog_id: id,
		})
		dispatch(actionCreator(DELETE_POSTS_SUCCESS, res))
		toast.success('Post deleted successfully')
		action()
	} catch (err) {
		dispatch(actionCreator(DELETE_POSTS_FAILURE, 'Unable to delete the blogPost at this time'));
		toast.error('Unable to delete the blogPost at this time')
	}
}

export default deleteBlog
