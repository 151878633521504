import Axios from 'axios'
import toast from 'react-hot-toast'
import actionCreator from '../../../utils/actionCreator'
import {
	REJECT_POSTS_FAILURE,
	REJECT_POSTS_LOADING,
	REJECT_POSTS_SUCCESS
} from '../../actionTypes'

const rejectBlog = (data, action) => async (dispatch) => {
	const { rejectId, reason } = data
	dispatch(actionCreator(REJECT_POSTS_LOADING))
	try {
		const { data: res } = await Axios.post('https://www.spendwise.ng/api/blog/reject_unpublish_post/', {
			action: 'reject',
			blog_id: rejectId,
			reason
		})
		dispatch(actionCreator(REJECT_POSTS_SUCCESS, res))
		toast.success('Blog rejected successfully')
		action()
	} catch (err) {
		dispatch(actionCreator(REJECT_POSTS_FAILURE, 'Unable to reject the blogPost at this time'));
		toast.error('Unable to reject the blogPost at this time')
	}
}

export default rejectBlog
