/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import * as actionTypes from '../../../actionTypes';
import axiosInstance from '../../../../utils/axios';

export const getSinglePost = (data) => (dispatch) => {
	dispatch(getSinglePostStart());
	axiosInstance
		.post('/blog/get_blog/', data)
		.then((response) => {
			// handle success
			dispatch(getSinglePostSuccess(response.data));
		})
		.catch((error) => {
			// handle error
			if (error.response) {
				// console.log('Error of response data budgets', error.response.data)
				dispatch(getSinglePostFailed(error.response.data));
			} else if (error.message) {
				// console.log(' error or message getSinglePost', error.message)
				dispatch(
					getSinglePostFailed({
						message: error.message,
					})
				);
			} else {
				dispatch(
					getSinglePostFailed({
						message: 'Error while retrieving post',
					})
				);
			}
			// console.log(error.request)
			// console.log('Error of config', error.config);
		});
};

export const getSinglePostStart = () => ({
	type: actionTypes.GET_SINGLE_POST_START,
});

export const getSinglePostSuccess = (data) => {
	const { message, blog_data, comments } = data;
	return {
		type: actionTypes.GET_SINGLE_POST_SUCCESS,
		message,
		blogData: blog_data,
		comments
	};
};

export const getSinglePostFailed = (error) => ({
	type: actionTypes.GET_SINGLE_POST_FAILED,
	error,
});
