/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

// import { AiOutlineClockCircle } from "react-icons/ai";

import { useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

import { RiArrowRightFill } from 'react-icons/ri';
import PlayStoreButton from '../../../components/customer/callToActions/playstore';
import PostBox from '../../../components/customer/postBox';
import classes from './categoryPage.module.scss';
import {
	clearAllSubsMessages,
	getAllByCat as getAllByCatFN,
	subscribe,
	getAllByCatNextorPrev,
} from '../../../store/actions';

const CategoryPage = (props) => {
	const { category } = useParams();
	const dispatch = useDispatch();

	const [newsletterEmail, setEmail] = React.useState('');

	const posts = useSelector((state) => state.postsReducer);
	const newsletterReducer = useSelector((state) => state.newsLetterReducer);

	const {
		getAllByCat,
		getAllByCatLoading,
		getAllByCatError,
		getAllByCatMessage,
		getAllByCatNext,
		getAllByCatPrev,
		//
		getAllByCatTotalPages,
		getAllByCatPageNumber,
	} = posts;

	useEffect(() => {
		const data = { category }

		dispatch(getAllByCatFN(data));

		// clear all newsletter messages
		dispatch(clearAllSubsMessages());
	}, [category, dispatch]);

	const submitEmailforNewsletter = () => {
		if (!newsletterEmail || newsletterEmail.trim() === '') {
			toast.error('Please input newsletter email');
			return;
		}
		dispatch(
			subscribe({
				email: newsletterEmail,
				page_url: window.location.href
			})
		);

		// clear input after submit
		setTimeout(() => {
			setEmail('');
		}, 500);
	};

	const handleTextChange = (event) => {
		setEmail(event.target.value);
		dispatch(clearAllSubsMessages())
	};

	/**
   * NEXT AND PREV GET POSTS API
   */
	const renderNextandPrevPostButtons = () => (
		<div className={classes['pagination-wrapper']}>
			<div>
				{getAllByCatPageNumber} of {getAllByCatTotalPages}
			</div>

			<div className={classes['grid-navigation-wrapper']}>
				<div
					onClick={
						getAllByCatPrev
							? () => dispatch(getAllByCatNextorPrev(getAllByCatPrev))
							: undefined
					}
					style={
						!getAllByCatPrev
							? { backgroundColor: 'rgba(27, 91, 172, 0.2)' }
							: {}
					}
				>
					<IoIosArrowBack />
				</div>
				<div
					onClick={
						getAllByCatNext
							? () => dispatch(getAllByCatNextorPrev(getAllByCatNext))
							: undefined
					}
					style={
						!getAllByCatNext
							? { backgroundColor: 'rgba(27, 91, 172, 0.2)' }
							: {}
					}
				>
					<IoIosArrowForward />
				</div>
			</div>
		</div>
	);

	const headingText = {
		save: 'Learn how you achieve your financial goals by saving with ease.',
		manage: 'You have no idea where your money goes, or how much you spend, you will always go broke. Learn how you can track and manage your expense and never be broke.',
		keep: 'Having a budget can help you control where your money goes to and save for future engagement. We would show everything you need to know about budgeting.',
		make: 'A quote says "a person is never broke until their major source of income is taken away from them." Learn how you can build a side hustle and make more money.',
		debt: 'Are you in a heap of debts? Are you wondering what steps to take to repay and be free? We would guide you on steps to take in debt repayment.',
		protect: 'You can never understand the importance of Insurance till you find yourself in a situation where all your finances is on the line. Learn more on insurance and protect your finances today.',
		grow: 'Saving isn’t enough to be financially independent but growing the money. Let’s take you on a journey to growing your money through investment.'
	}

	return (
		<div className={classes['page-wrapper']}>
			<Toaster containerStyle={{ fontWeight: 'bold', fontSize: 12 }}/>
			<div className={classes['category-details-wrapper']}>
				<div className={classes.topName}>
					<div className={classes.bar}/>
					<div className={classes['category-name']}>{category === 'debt' ? 'Repay' : category.charAt(0).toUpperCase() + category.slice(1)} Money</div>
				</div>
				<div className={classes['category-details']}>
					{headingText[category]}
				</div>
			</div>
			{getAllByCatLoading ? (
				<div className={classes.loader}>
					<box-icon name='loader-circle' animation='spin' rotate='90' size='lg' ></box-icon>
				</div>
			) : (
				!getAllByCatLoading && getAllByCat.length === 0 ? (
					<p className='lg:text-lg md:text-base text-xs font-bold text-center '>No blog has been added for this category yet</p>
				)
					: getAllByCat && getAllByCat.length > 0 && (
						<div className={classes['articles-grid-wrapper']}>
							{getAllByCat.map((each, index) => <PostBox
								key={each + index}
								data={each}
								id={index}
							/>)}
						</div>
					)
			)}
			{getAllByCat && getAllByCat.length > 6 && renderNextandPrevPostButtons()}
			<div className={classes.inSub}>
				<div className={classes['newsletter-dotted-image']} />
				<div className={classes['newsletter-card']}>
					<div className={classes['newsletter-text']}>
            Subscribe for newsletter
					</div>
					<div className={classes.newsletterWrap}>
						<div className={classes['newsletter-input-wrap']}>
							<input
								value={newsletterEmail}
								onChange={handleTextChange}
								type="text"
								placeholder="Enter email address"
							/>
							<div
								onClick={submitEmailforNewsletter}
								style={{
									backgroundColor: '#fff',
									height: 40,
									width: 40,
									borderRadius: 20,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									cursor: 'pointer'
								}}
							>
								{newsletterReducer.loading ? (
  									<box-icon name='loader-circle' animation='spin' rotate='90' size='sm'></box-icon>
  								)
  								: <RiArrowRightFill
  									style={{
  										color: '#1B7DBF',
  									}}
  								/>}
							</div>
						</div>
						{newsletterReducer.error && (
							<div className={classes['newsletter-error']}>
								{newsletterReducer.error.message}
							</div>
						)}
						{/* )} */}
					</div>
				</div>
			</div>

			<PlayStoreButton
				containerStyle={{
					position: 'fixed',
					zIndex: 1000,
				}}
			/>
		</div>
	);
};

export default CategoryPage;
