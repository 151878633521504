import { combineReducers } from 'redux'
import userReducer from './blogin.reducer'
import commentReducer from './customer/comment'
import postsReducer from './customer/posts'
import newsLetterReducer from './customer/newsletter'
import summaryReducer from './admin/summary.reducer'
import bloggerDetailsReducer from './blogger/blogger-info.reducer'
import {
	bloggerBlogsReducer, rejectedBlogReducer, publishedBlogsReducer, getDraftsReducer
} from './blogger/blogger-blog.reducer'
import bloggerRecentBlogsReducer from './blogger/blogger-recent.reducer'
import unpublished from './admin/unpublished.reducer'
import approveBlogReducer from './admin/approveBlog.reducer'
import allBloggers from './admin/getBloggers.reducer'
import suspendBlogger from './admin/suspendBlogger.reducer'
import rejectBlogReducer from './admin/rejectBlog.reducer'
import unpublishBlogReducer from './admin/unpublishBlog.reducer'
import deleteBlogReducer from './admin/deleteBlog.reducer'
import registerBlogger from './register.reducer'
import allAdminBlogs from './admin/allBlogAdmin.reducer'

const rootReducer = combineReducers({
	registerBlogger,
	userReducer,
	postsReducer,
	commentReducer,
	newsLetterReducer,
	summaryReducer,
	bloggerDetailsReducer,
	bloggerBlogsReducer,
	bloggerRecentBlogsReducer,
	unpublished,
	approveBlogReducer,
	allBloggers,
	suspendBlogger,
	rejectedBlogReducer,
	publishedBlogsReducer,
	getDraftsReducer,
	rejectBlogReducer,
	unpublishBlogReducer,
	deleteBlogReducer,
	allAdminBlogs
});

export default rootReducer;
