/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import draftToHtml from 'draftjs-to-html';
import classes from './Post.module.scss';

import EditPost from '../../Modals/EditPost/Edit';
import CreatePost from '../../Modals/CreatePost/Create';
import Nav from '../../../../components/content-writers/Nav/Nav';
import Sidemenu from '../../../../components/content-writers/Sidemenu/Sidemenu';
import Comments from '../../../admin/Pages/Comments/Comments';

import edit from '../../../../assets/admin/edit.png';
import bck from '../../../../assets/admin/back.png';

const Post = (props) => {
	const [open, setOpen] = useState(false);
	const [draftModal, setDraftModal] = useState(false);
	const [blog, setBlog] = useState({});
	const [comment, setComment] = useState([]);
	const [oneBlog, setOneBlog] = useState({});
	const [toggle, setToggle] = useState(false)

	const openEdit = (e) => {
		setOpen(!open);
		setOneBlog(e);
	};

	const openModal = (val) => {
		setDraftModal(!draftModal);
		setOneBlog(val);
	};

	const createMarkup = (html) => ({
		__html: DOMPurify.sanitize(html)
	})

	// <----get user token from saved storage---->
	const userToken = JSON.parse(localStorage.getItem('authToken'));

	useEffect(() => {
		if (props.location.blog) {
			const blogId = props.location.blog.post.id;

			localStorage.setItem('blogId', JSON.stringify(props.location.blog.post.id));

			const url = 'https://www.spendwise.ng/api/blog/get_blog/';

			axios
				.post(
					url,
					{ id: blogId },
					{
						headers: {
							Authorization: `Token ${userToken}`,
							'content-type': 'application/json'
						}
					}
				)
				.then((res) => {
					if (res.status === 200) {
						setBlog(res.data.blog_data);
						setComment(res.data.comments)
					}
				})
				.catch((err) => err);
		} else {
			const currentId = JSON.parse(localStorage.getItem('blogId'));

			const url = 'https://www.spendwise.ng/api/blog/get_blog/';

			axios
				.post(
					url,
					{ id: currentId },
					{
						headers: {
							Authorization: `Token ${userToken}`,
							'content-type': 'application/json'
						}
					}
				)
				.then((res) => {
					if (res.status === 200) {
						// console.log(res.data);
						setBlog(res.data.blog_data);
						setComment(res.data.comments)
					}
				})
				.catch((err) => err);
		}
		return () => {
			localStorage.removeItem('blogId');
		};
	}, [props.location.blog, userToken]);

	// <----converting the timestamp to normal date time---->
	const DATE_OPTIONS = {
		weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
	};

	return (
		<div>
			<Sidemenu />
			<Nav />
			<section className={classes['post-container']}>
				{blog && !blog.id
					? (<div className={classes.loader}>
						<box-icon name='loader-circle' animation='spin' rotate='90' size='lg' ></box-icon>
					</div>)
					: (<>
						<div className={classes['post-header']}>
							<h4 onClick={props.history.goBack}>
								<img src={bck} alt="img" />
						Back
							</h4>

							<div>
								{!blog.is_draft && !blog.in_review && <h5
									onClick={() => {
										openEdit(blog);
									}}
								>
									<img src={edit} alt="img" />
							Edit
								</h5>}
							</div>
						</div>

						<div className={classes.title}>
							<div className={classes.topic}>
								<h3>Title</h3>
								<h2>{blog.title}</h2>
							</div>
							<div className={classes.category}>
								<h3>Category</h3>
								<h2>{blog.category}</h2>
							</div>
							<div className={classes.views}>
								<h3>Blog Views</h3>
								<h2>{blog.views}</h2>
							</div>
							<div className={classes.details}>
								<h3>
							Created:
									{` ${new Date(blog.date_created).toLocaleDateString('en-US', DATE_OPTIONS)}`}
								</h3>
								{blog && blog.is_approved && <h3>Published: {` ${new Date(blog.approve_date).toLocaleDateString('en-US', DATE_OPTIONS)}`}</h3>}
							</div>
							<div>
								<h5
									id={
										blog.is_draft === true ? (
											classes.brownLabel
										) : blog.is_approved === true ? (
											classes.greenLabel
										) : blog.in_review === true ? (
											classes.yellowLabel
										) : blog.review_needed === true ? (
											classes.redLabel
										) : classes.neutral
									}
								>
									{blog.is_draft === true ? (
										'Draft'
									) : blog.is_approved === true ? (
										'Published'
									) : blog.in_review === true ? (
										'In Review'
									) : blog.review_needed === true ? (
										'Rejected'
									) : 'Not Submitted'
									}
								</h5>
							</div>
						</div>

						<div className={classes['comment-and-content']}>
							<h5 onClick={() => setToggle(false)} className={!toggle ? `${classes.on}` : ''}>
						Content
							</h5>
							<h5 onClick={() => setToggle(true)} className={toggle ? `${classes.on}` : ''}>
						Comments
							</h5>
						</div>

						{!toggle ? (
							<section className={classes['main-post-section']}>
								<div>
									<h4>Image and Content</h4>
									{!blog.in_review && (
										<h6
											className='font-bold'
											onClick={() => (blog.is_draft ? openModal(blog)
												: openEdit(blog))
											}
										>
											<img src={edit} alt="img" />
											{blog.is_draft ? 'Continue Writing' : 'Edit'}
										</h6>
									)}
								</div>
								<hr />
								<div className={classes['img-container']}>
									<img src={blog.image} alt="img" id={classes.pic} />
								</div>
								{blog && (
									<div className={classes.body}>
										<div
											dangerouslySetInnerHTML={
												createMarkup(
													draftToHtml(JSON.parse(blog.is_draft ? blog.draft : blog.body), '', true,)
												)}>
										</div>
									</div>
								)}
							</section>
						) : (
							<Comments data={comment} />
						)}
					</>)}
			</section>

			<CreatePost open={draftModal} onClose={openModal} details={blog} />
			<EditPost open={open} onClose={openEdit} details={oneBlog} />
		</div>
	);
};

Post.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object
}

export default withRouter(Post);
