import React from 'react';

const playStoreButton = (props) => {
	const { containerStyle } = props;

	return (
		<div
			style={containerStyle}
		>
			<div
				style={{
					borderRadius: 12,
					//   backgroundColor: "rgba(255,255,255,0.3)",
					backgroundColor: 'rgba(0,0,0,0.3)',
					height: 56,
					width: 150,
					marginTop: 26,
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						padding: 10,
						color: '#fff',
					}}
				>
					<div>G</div>
					<div>
						<div style={{ fontSize: 12 }}>Get it on</div>
						<div style={{ fontSize: 17 }}> Google Play</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default playStoreButton;
