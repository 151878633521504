// BLOGIN
export const USER_SIGN_IN_REQUEST = 'USER_SIGN_IN_REQUEST';
export const USER_SIGN_IN_SUCCESS = 'USER_SIGN_IN_SUCCESS';
export const USER_SIGN_IN_FAILURE = 'USER_SIGN_IN_FAILURE';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';

export const LOGOUT_USER = 'LOGOUT_USER'

// Dashboard summary
export const SUMMARY_REQUEST = 'SUMMARY_REQUEST';
export const SUMMARY_SUCCESS = 'SUMMARY_SUCCESS';
export const SUMMARY_FAILURE = 'SUMMARY_FAILURE';

// POSTS
export const GET_ALL_START = 'GET_ALL_START';
export const GET_ALL_SUCCESS = 'GET_ALL_SUCCESS';
export const GET_ALL_FAILED = 'GET_ALL_FAILED';

export const GET_ALL_ADMIN_START = 'GET_ALL_ADMIN_START';
export const GET_ALL_ADMIN_SUCCESS = 'GET_ALL_ADMIN_SUCCESS';
export const GET_ALL_ADMIN_FAILED = 'GET_ALL_ADMIN_FAILED';

export const GET_ALL_BY_CAT_START = 'GET_ALL_BY_CAT_START';
export const GET_ALL_BY_CAT_SUCCESS = 'GET_ALL_BY_CAT_SUCCESS';
export const GET_ALL_BY_CAT_FAILED = 'GET_ALL_BY_CAT_FAILED';

// RECENTLY PUBLISHED
export const GET_RECENT_START = 'GET_RECENT_START';
export const GET_RECENT_SUCCESS = 'GET_RECENT_SUCCESS';
export const GET_RECENT_FAILED = 'GET_RECENT_FAILED';

export const GET_SINGLE_POST_START = 'GET_SINGLE_POST_START';
export const GET_SINGLE_POST_SUCCESS = 'GET_SINGLE_POST_SUCCESS';
export const GET_SINGLE_POST_FAILED = 'GET_SINGLE_POST_FAILED';

//  LIKE POSTS
export const LIKE_POST_START = 'LIKE_POST_START';
export const LIKE_POST_SUCCESS = 'LIKE_POST_SUCCESS';
export const LIKE_POST_FAILED = 'LIKE_POST_FAILED';

// COMMENTS
export const ADD_COMMENT_START = 'ADD_COMMENT_START';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAILED = 'ADD_COMMENT_FAILED';

// NEWSLETTER
export const SUBSCRIBE_START = 'SUBSCRIBE_START';
export const SUBSCRIBE_SUCCESS = 'SUBSCRIBE_SUCCESS';
export const SUBSCRIBE_FAILED = 'SUBSCRIBE_FAILED';

export const CLEAR_SUBS_MESSAGES = 'CLEAR_SUBS_MESSAGES';

// UTILITY MODALS
export const SHOW_SUCCESS_MODAL = 'SHOW_SUCCESS_MODAL';
export const HIDE_SUCCESS_MODAL = 'HIDE_SUCCESS_MODAL';
export const SHOW_ERROR_MODAL = 'SHOW_ERROR_MODAL';
export const HIDE_ERROR_MODAL = 'HIDE_ERROR_MODAL';

// BLOGGER DETAILS
export const GET_BLOGGER_DETAILS_SUCCESS = 'GET_BLOGGER_DETAILS_SUCCESS';
export const GET_BLOGGER_DETAILS_FAILURE = 'GET_BLOGGER_DETAILS_FAILURE';
export const GET_BLOGGER_DETAILS_LOADING = 'GET_BLOGGER_DETAILS_LOADING';

export const GET_BLOGGER_POSTS_SUCCESS = 'GET_BLOGGER_POSTS_SUCCESS';
export const GET_BLOGGER_POSTS_FAILURE = 'GET_BLOGGER_POSTS_FAILURE';
export const GET_BLOGGER_POSTS_LOADING = 'GET_BLOGGER_POSTS_LOADING';

export const RECENTLY_SUBMMITED_SUCCESS = 'RECENTLY_SUBMMITED_SUCCESS';
export const RECENTLY_SUBMMITED_FAILURE = 'RECENTLY_SUBMMITED_FAILURE';
export const RECENTLY_SUBMMITED_LOADING = 'RECENTLY_SUBMMITED_LOADING';

export const GET_UNAPPROVED_SUCCESS = 'GET_UNAPPROVED_SUCCESS';
export const GET_UNAPPROVED_FAILURE = 'GET_UNAPPROVED_FAILURE';
export const GET_UNAPPROVED_LOADING = 'GET_UNAPPROVED_LOADING';

export const APPROVE_SUCCESS = 'APPROVE_SUCCESS';
export const APPROVE_FAILURE = 'APPROVE_FAILURE';
export const APPROVE_REQUEST = 'APPROVE_REQUEST';

export const GET_BLOGGERS_REQUEST = 'GET_BLOGGERS_REQUEST';
export const GET_BLOGGERS_SUCCESS = 'GET_BLOGGERS_SUCCESS';
export const GET_BLOGGERS_FAILURE = 'GET_BLOGGERS_FAILURE';

export const SUSPEND_BLOGGERS_REQUEST = 'SUSPEND_BLOGGERS_REQUEST';
export const SUSPEND_BLOGGERS_SUCCESS = 'SUSPEND_BLOGGERS_SUCCESS';
export const SUSPEND_BLOGGERS_FAILURE = 'SUSPEND_BLOGGERS_FAILURE';

// Blogger

export const GET_REJECTED_SUCCESS = 'GET_REJECTED_SUCCESS';
export const GET_REJECTED_FAILURE = 'GET_REJECTED_FAILURE';
export const GET_REJECTED_LOADING = 'GET_REJECTED_LOADING';

export const GET_PUBLISHED_SUCCESS = 'GET_PUBLISHED_SUCCESS';
export const GET_PUBLISHED_FAILURE = 'GET_PUBLISHED_FAILURE';
export const GET_PUBLISHED_LOADING = 'GET_PUBLISHED_LOADING';

export const GET_DRAFT_SUCCESS = 'GET_DRAFT_SUCCESS';
export const GET_DRAFT_FAILURE = 'GET_DRAFT_FAILURE';
export const GET_DRAFT_LOADING = 'GET_DRAFT_LOADING';

export const SEARCH_POSTS_SUCCESS = 'SEARCH_POSTS_SUCCESS'
export const SEARCH_POSTS_LOADING = 'SEARCH_POSTS_LOADING'
export const SEARCH_POSTS_FAILURE = 'SEARCH_POSTS_FAILURE'

export const REJECT_POSTS_SUCCESS = 'REJECT_POSTS_SUCCESS'
export const REJECT_POSTS_LOADING = 'REJECT_POSTS_LOADING'
export const REJECT_POSTS_FAILURE = 'REJECT_POSTS_FAILURE'

export const DELETE_POSTS_SUCCESS = 'DELETE_POSTS_SUCCESS'
export const DELETE_POSTS_LOADING = 'DELETE_POSTS_LOADING'
export const DELETE_POSTS_FAILURE = 'DELETE_POSTS_FAILURE'

export const UNPUBLISH_POSTS_SUCCESS = 'UNPUBLISH_POSTS_SUCCESS'
export const UNPUBLISH_POSTS_LOADING = 'UNPUBLISH_POSTS_LOADING'
export const UNPUBLISH_POSTS_FAILURE = 'UNPUBLISH_POSTS_FAILURE'
