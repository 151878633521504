/* eslint-disable no-use-before-define */
import * as actionTypes from '../../../actionTypes';
import axiosInstance from '../../../../utils/axios';

import { getSinglePost } from '../get'

export const addComment = (data) => {
	// const options = {
	//   headers: {
	//     Authorization: `Token ${token}`,
	//   },
	// };
	const currentPostId = data.blog_id

	return (dispatch) => {
		dispatch(addCommentStart());
		axiosInstance
			.post('/blog/comment/', data)
			.then((response) => {
				// console.log('response ', response.data)
				// handle success
				dispatch(addCommentSuccess(response.data));

				// get current post again
				dispatch(getSinglePost({
					id: currentPostId
				}))
			})
			.catch((error) => {
				// handle error
				if (error.response) {
					// console.log('Error of response add comment', error.response.data)
					dispatch(addCommentFailed(error.response.data));
				} else if (error.message) {
					// console.log(' error or message addComment', error.message)
					dispatch(
						addCommentFailed({
							message: error.message,
						})
					);
				} else {
					dispatch(
						addCommentFailed({
							message: 'Error while Subscribing',
						})
					);
				}
				// console.log(error.request)
				// console.log('Error of config', error.config);
			});
	};
};

export const addCommentStart = () => ({
	type: actionTypes.ADD_COMMENT_START,
});

export const addCommentSuccess = (data) => {
	const { message } = data;
	return {
		type: actionTypes.ADD_COMMENT_SUCCESS,
		message
	};
};

export const addCommentFailed = (error) => ({
	type: actionTypes.ADD_COMMENT_FAILED,
	error
});
