import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Blogin from '../components/Auth/Blogin';
import Signup from '../components/Auth/Signup.jsx';

import AdminPost from '../containers/admin/Pages/Post/Post';
import Users from '../containers/admin/Pages/Users/Users';
import Admin from '../containers/admin/Pages/Homepage/Homepage';

import CardDetail from '../components/CardDetailInfo/cardDetailInfo'

import Blogger from '../containers/content-writers/Pages/Homepage/Homepage';
import BloggerPost from '../containers/content-writers/Pages/Post/Post';

const currentUser = JSON.parse(localStorage.getItem('user'))

const RoutesAdmin = () => (
	<Router>
		<div>
			{currentUser && currentUser.suspended
				&& (<h1 style={{
					color: 'white', background: 'red', fontSize: 20, fontStyle: 'bold', textAlign: 'center', position: 'fixed', top: 0, width: '100vw', zIndex: 1
				}}>You have been Suspended!</h1>)
			}
			<Switch>
				{/* // <--------admin routes--------> */}
				<Route path="/blogin/admin">
					<Admin />
				</Route>

				<Route path="/admin/users">
					<Users />
				</Route>

				<Route path="/admin/post">
					<AdminPost />
				</Route>

				{/* // <---------content writers routes------> */}
				<Route path="/blogin/blogger">
					<Blogger />
				</Route>

				<Route path="/blogger/post">
					<BloggerPost />
				</Route>

				{/* //<------general login route-------> */}
				<Route exact path="/blogin">
					<Blogin />
				</Route>

				<Route exact path="/register" component={Signup} />

				<Route exact path='/:userType/:cardName' component={CardDetail} />
			</Switch>
		</div>
	</Router>
);

export default RoutesAdmin;
