import {
	USER_SIGN_IN_FAILURE,
	USER_SIGN_IN_REQUEST,
	USER_SIGN_IN_SUCCESS,
	LOGOUT_USER
} from '../actionTypes'

const initialState = {
	user: {},
	loading: false,
	error: null
}

export default function userReducer(state = initialState, action) {
	switch (action.type) {
	case USER_SIGN_IN_REQUEST:
		return { ...state, loading: true };
	case USER_SIGN_IN_FAILURE:
		return { ...state, loading: false, error: action.payload };
	case USER_SIGN_IN_SUCCESS:
		return { ...state, loading: false, user: action.payload };
	case LOGOUT_USER:
		return {
			...state, user: null, loading: false, error: null
		};
	default:
		return state;
	}
}
