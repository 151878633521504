/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React from 'react';

import moment from 'moment';

import { GoReply } from 'react-icons/go';
import { FaUser } from 'react-icons/fa';
import classes from './commentBox.module.scss';

const commentBox = (props) => {
	const { data, handleReply } = props;
	const { comment, in_reply_to, commentReplies } = data;
	// console.log('uvenv', in_reply_to && (in_reply_to.body === data.body))

	const renderCommentBox = (data) => (
		<div
			className={classes.container}
			style={
				(in_reply_to && (in_reply_to.body === data.body))
					? {
						width: '100%',
						marginLeft: 'auto',
						boxShadow: 'none'
					}
					: undefined
			}
		>
			<div className={classes['comment-text']}>{data.body}</div>
			<div className='flex items-start justify-between flex-col mt-2 md:mt-0 md:flex-row md:items-center lg:justify-start'>
				<div className={classes.author}>
					<FaUser
						style={{
							color: '#2699FB',
						}}
					/>
					{data.name}
				</div>
				<div className='lg:ml-5'
					style={{
						display: 'flex',
						gap: 20,
					}}
				>
					<span
						onClick={() => handleReply(data)}
						className={classes.reply}
					>
						<GoReply style={{ color: 'red', fontSize: 14 }} /> Reply
					</span>
					<span className={classes.date}>
						{moment(data.date).format('DD-MM-YYYY')}
					</span>
				</div>
			</div>
			{commentReplies.length > 0 && (
				<div className={classes.replyContainer}>
					{commentReplies.map((reply, index) => (
						<div key={index}>
							<div className={classes['comment-text']}>{reply.comment.body}</div>
							<div className='flex items-start justify-between flex-col mt-2 md:mt-0 md:flex-row md:items-center lg:justify-start'>
								<div className={classes.author}>
									<FaUser
										style={{
											color: '#2699FB',
										}}
									/>
									{reply.comment.name}
								</div>
								<div className='lg:ml-5'
									style={{
										display: 'flex',
										gap: 20,
									}}
								>
									{/* <span
										onClick={() => handleReply(reply.comment)}
										className={classes.reply}
									>
										<GoReply style={{ color: 'red', fontSize: 14 }} /> Reply
									</span> */}
									<span className={classes.date}>
										{moment(reply.comment.date).format('DD-MM-YYYY')}
									</span>
								</div>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);

	// const renderReplyToComments = (data) => (
	// 	<div className={classes.container}>
	// 		<div>
	// 			<div className={classes.replying}>Replying to {in_reply_to.name}</div>
	// 			<div className={classes['comment-text']}>{comment.body}</div>
	// 			<div className='flex items-start justify-between flex-col mt-2 md:mt-1 md:flex-row md:items-center lg:justify-start'>
	// 				<div className={classes.author}>
	// 					<FaUser
	// 						style={{
	// 							color: '#2699FB',
	// 						}}
	// 					/>
	// 					<span>{comment.name}</span>
	// 				</div>
	// 				<div className='lg:ml-5'
	// 					style={{
	// 						display: 'flex',
	// 						gap: 20,
	// 					}}
	// 				>
	// 					<span
	// 						onClick={() => handleReply(comment)}
	// 						className={classes.reply}
	// 					>
	// 						<GoReply style={{ color: 'red', fontSize: 14 }} /> Reply
	// 					</span>
	// 					<span className={classes.date}>
	// 						{moment(comment.date).format('DD-MM-YYYY')}
	// 					</span>
	// 					{/* <div className={classes.likes}>
	// 							<FaHeart style={{ color: 'red' }} />
	// 							<span>609</span>
	// 						</div> */}
	// 				</div>
	// 			</div>
	// 		</div>
	// 		{/* <>{renderCommentBox(in_reply_to)}</> */}
	// 	</div>
	// );

	return (
		<div className={classes.comment}>
			{renderCommentBox(comment)}
		</div>
	);
};

export default commentBox;
