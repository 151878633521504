/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdPerson, IoIosText } from 'react-icons/io';
import Card from '../../../../components/Card/card.jsx'
import styles from './Homepage.module.scss';
import getSummary from '../../../../store/actions/admin/summary.action';
import { getAllAdminPosts, getAllPostsNextorPrev, getRecentlyPublished } from '../../../../store/actions/index';
import Nav from '../../../../components/admin/Nav/Nav';
import Sidemenu from '../../../../components/admin/Sidemenu/Sidemenu';
import Dropdown from '../../../../components/Dropdown/dropdown.jsx';
import SearchInput from '../../../../components/SearchInput/searchInput.jsx';
import { getBloggers } from '../../../../store/actions/admin/adminBlogger.action';

/* eslint-disable import/extensions */
import blogue from '../../../../assets/admin/blue-blog';
/* eslint-disable import/extensions */

const getOnlineStatus = () => (typeof navigator !== 'undefined'
&& typeof navigator.onLine === 'boolean' ? navigator.onLine : true);

const Homepage = () => {
	const dispatch = useDispatch()
	const history = useHistory()

	const [online, setIsOnline] = useState(getOnlineStatus());

	const { summary } = useSelector(({ summaryReducer }) => ({
		loading: summaryReducer.loading,
		error: summaryReducer.error,
		summary: summaryReducer.summary
	}));

	const {
		allPosts, allLoading, totalPage, next, prev, pageNo
	} = useSelector(({ allAdminBlogs }) => ({
		allLoading: allAdminBlogs.getAllLoading,
		allPosts: allAdminBlogs.getAll,
		next: allAdminBlogs.getAllNextUrl,
		prev: allAdminBlogs.getAllPrevUrl,
		totalPage: allAdminBlogs.getAllTotalPages,
		pageNo: allAdminBlogs.getAllPageNumber,
	}))

	const { recentPosts, recentLoading } = useSelector(({ postsReducer }) => ({
		recentPosts: postsReducer.recentlyPublished,
		recentLoading: postsReducer.loading
	}));

	const { bloggers } = useSelector(({ allBloggers }) => ({
		bloggers: allBloggers.bloggers
	}))

	const setOnline = () => setIsOnline(true);
	const setOffline = () => setIsOnline(false);

	// Register the event listeners
	useEffect(() => {
		window.addEventListener('online', setOnline);
		window.addEventListener('offline', setOffline);
		dispatch(getSummary())
		dispatch(getAllAdminPosts('created'))
		dispatch(getRecentlyPublished())
		dispatch(getBloggers())

		// cleanup if we unmount
		return () => {
			window.removeEventListener('online', setOnline);
			window.removeEventListener('offline', setOffline);
		};
	}, [dispatch]);

	const sortByCreated = () => {
		dispatch(getAllAdminPosts('created'))
	}

	const sortByPublished = () => {
		dispatch(getAllAdminPosts('published'))
	}

	// <---- getting next page--->
	const nextPosts = () => {
		dispatch(getAllPostsNextorPrev(next, 'admin'))
	};

	// <---- getting prev page--->
	const prevPosts = () => {
		dispatch(getAllPostsNextorPrev(prev, 'admin'))
	};

	// <----converting the timestamp to normal date time---->
	const DATE_OPTIONS = {
		weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
	};

	const jumpToSection = (e) => {
		e.preventDefault()
		window.location.href = '#all-Posts'
	}

	const viewAllUnpublished = (e) => {
		e.preventDefault()
		history.push('/admin/unpublished')
	}

	const goToUsers = (e) => {
		e.preventDefault()
		history.push('/admin/users')
	}

	return (
		<section className={styles.section}>
			<Sidemenu />
			<Nav />

			{!online ? (
				<div
					style={{
						float: 'right',
						width: '85%',
						height: '70vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						color: 'GrayText'
					}}
				>
					<center>
						<h3 style={{ fontSize: '1.4rem' }}>
							Ooops!!!, seems you are offline...Check your network and try again
						</h3>{' '}
					</center>
				</div>
			) : (
				<section className={styles['homepage-container']}>
					<header>
						<div>
							<h2>Content Management</h2>
							<p>Monitor user statistics easily!</p>
						</div>
					</header>

					<div className={styles['action-lists']}>
						<Card
							image = {blogue}
							title = 'Blog Posts'
							value = {summary.blog_count}
							text = 'Total Articles'
							color
							action = {jumpToSection}
						/>
						<Card
							image = {blogue}
							title = 'Published'
							value = {summary.published}
							text = 'Articles'
							action = {jumpToSection}
						/>
						<Card
							image = {blogue}
							title = 'Unpublished'
							value = {summary.unpublished}
							text = 'Articles'
							action = {viewAllUnpublished}
						/>
						<Card
							image = {blogue}
							title = 'Deleted'
							value = {summary.deleted}
							text = 'Articles'
						/>
						<Card
							image = {blogue}
							title = 'Contributors'
							value = {summary.total_bloggers}
							text = 'Articles'
							action = {goToUsers}
						/>
					</div>

					<section className={styles['publishing-section']}>
						<h3 className={styles.pubhead}>Recently Published</h3>
						<hr />
						{recentLoading ? (
							<div className={styles.loader}>
								<box-icon name='loader-circle' animation='spin' rotate='90' size='lg' ></box-icon>
							</div>
						) : (
							!recentPosts || recentPosts.length === 0 ? (
								<article style={{ display: 'block' }}>
									<center>
										<p>
											<b>No posts from bloggers as of this time</b>
										</p>
									</center>
								</article>
							) : (
								recentPosts && recentPosts.map((recentPost) => (
									<article key={recentPost.id} className={styles.recentPost}>
										<div className={styles.postImage}>
											<img src={recentPost.image} alt='Post Image' />
										</div>
										<div className={styles.details}>
											<Link
												to={{
													pathname: '/admin/post',
													blog: {
														post: recentPost
													}
												}}
												id={styles.link}
											>
												<h4>{recentPost.title}</h4>
											</Link>
											<h5 className={styles.cat}>Category: {recentPost.category}</h5>

											<div className={styles.time}>
												<h6>
													{new Date(recentPost.approve_date).toLocaleDateString(
														'en-US',
														DATE_OPTIONS
													)}
												</h6>
												<h6>
													<IoIosText id={styles.msgIcon} />
													{recentPost.comment_count ? recentPost.comment_count : 0} Comments
												</h6>
												<h6>
													<IoMdPerson size={18} className={styles['person-icon']} />
													{recentPost.author_name}
												</h6>
											</div>
										</div>
									</article>
								))
							)
						)}
					</section>

					<section className={styles['all-post']} id="all-Posts">
						<div className={styles['blog-header']}>
							<h3>All Blog Posts</h3>
							<div className={styles.searchContainer}>
								<SearchInput info='Search blog'/>
								<Dropdown
									options={
										[...bloggers].map((blogger) => (
											{ name: blogger.username }
										))
									}
									size='medium'
								/>
							</div>
							<Dropdown options={[{ name: 'Most Recently Created', action: sortByCreated }, { name: 'Most Recently Published', action: sortByPublished }]}/>
						</div>

						{allLoading ? (
							<div className={styles.loader}>
								<box-icon name='loader-circle' animation='spin' rotate='90' size='lg' ></box-icon>
							</div>
						) : (
							<table>
								<thead>
									<tr>
										<td>Title/Caption</td>
										<td>Date Created</td>
										<td>Date Published</td>
										<td>Author Name</td>
									</tr>
								</thead>
								<tbody>
									{!allPosts || allPosts.length === 0 ? (
										<div style={{ display: 'block', paddingTop: 30, borderTop: '.5px solid #dddddd' }}>
											<center>
												<p>
													<b>No posts as at this time</b>
												</p>
											</center>
										</div>
									) : (
										allPosts.map((post) => (
											<tr className={styles['post-list']} key={post.id}>
												<Link to={{
													pathname: '/admin/post',
													blog: { post }
												}}
												id={styles.link}
												className={styles.justLink}
												>
													<td >{post.title}</td>
												</Link>
												<td>{new Date(post.date_created).toLocaleDateString('en-US', DATE_OPTIONS)}</td>
												<td>{new Date(post.approve_date).toLocaleDateString('en-US', DATE_OPTIONS)}</td>

												<td>{post.author_name}</td>

												<td
													className={
														post.is_approved ? (
															styles.greenLabel
														) : (post.in_review ? (
															styles.blueLabel
														) : (
															styles.redLabel
														))
													}
												>
													{post.is_approved ? (
														'Published'
													) : (post.in_review ? (
														'To be Reviewed'
													) : (
														'Deleted'
													))}
												</td>
											</tr>
										))
									)}
								</tbody>
							</table>
						)}

						<footer className={styles.footer}>
							<p
								onClick={prevPosts}
								className={
									prev ? (
										styles['back-button-active']
									) : (
										styles['back-button-inactive']
									)
								}
							>
								Back
							</p>

							<p className={styles['middle-footer']}>Page {pageNo} of {totalPage}</p>

							<p
								onClick={nextPosts}
								className={
									next ? (
										styles['next-button-active']
									) : (
										styles['next-button-inactive']
									)
								}
							>
								Next
							</p>
						</footer>
					</section>
				</section>
			)}
		</section>
	);
};

export default Homepage;
