/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '../Button/button.jsx'
import styles from './actionModal.module.scss'

const ActionModal = ({
	action,
	onClose,
	open,
	review,
	unpublish,
	toDelete,
	suspend,
	suspensionStatus,
	toApprove,
	loading,
	signupSuccess
}) => {
	const [text, setText] = useState('')

	const handleChange = (e) => {
		setText(e.target.value)
	}

	const onclose = (e) => {
		setText('')
		onClose(e)
	}

	const getAction = (e) => {
		e.preventDefault()
		action(text)
	}

	const actAndCloseModal = async (e) => {
		e.preventDefault()
		await getAction(e)
		onclose(e)
	}

	if (!open) {
		return null;
	}

	return (
		<div className={styles.modal}>
			<div className={`${styles.content} ${(toDelete || suspend || toApprove) && styles.delete} ${signupSuccess && styles.success}`}>
				{!review && !toDelete && !suspend && !toApprove && !signupSuccess
				&& <>
					<p>Please enter your feedback</p>
					<textarea
						name='review'
						id='review'
						cols="30"
						rows="10"
						onChange={handleChange}
						value={text}>
					</textarea>
					<div className={styles.action}>
						<Button name='Cancel' action={onclose}/>
						<Button name={loading ? (
							<box-icon name='loader-circle' animation='spin' rotate='90' size='sm' color='white'></box-icon>
						) : (unpublish ? 'Unpublish' : 'Reject')} action={actAndCloseModal} danger disAllow={text === '' && true} />
					</div>
				</>
				}
				{review
				&& <>
					<p>Admin feedback</p>
					<textarea
						disabled
						name='review'
						id='review'
						cols="30"
						rows="10"
						onChange={handleChange}
						value={review}>
					</textarea>
					<Button name='Cancel' action={onclose} styles={{ alignSelf: 'center' }}/>
				</>
				}
				{(toDelete || suspend || toApprove)
					&& <>
						 <div className={styles.question}>Are you sure?</div>
						 <div className={styles.action}>
					   	<Button name='Cancel' action={onclose}/>
							<Button
								name={loading ? (
									<box-icon name='loader-circle' animation='spin' rotate='90' size='sm' color='white'></box-icon>
								) : (suspend ? (suspensionStatus ? 'Lift Suspension' : 'Suspend') : toApprove ? 'Approve' : 'Delete')}
								action={actAndCloseModal} danger />
						</div>
					</>
				}
				{signupSuccess
					&& <>
						 <div className={styles.question}>
							 Signed up Successfully, please contact the Admin to get onboarded as a blogger
						</div>
						 <div className={styles.action}>
							<Button
								name='OK'
								action={actAndCloseModal} danger />
						</div>
					</>
				}
			</div>
		</div>
	)
}

ActionModal.propTypes = {
	action: PropTypes.func,
	onClose: PropTypes.func,
	open: PropTypes.bool,
	unpublish: PropTypes.bool,
	toDelete: PropTypes.bool,
	suspend: PropTypes.bool,
	suspensionStatus: PropTypes.bool,
	review: PropTypes.string,
	toApprove: PropTypes.bool,
	loading: PropTypes.bool,
	signupSuccess: PropTypes.bool
}

export default ActionModal
