import Axios from 'axios'
import actionCreator from '../../../utils/actionCreator'
import {
	GET_UNAPPROVED_FAILURE,
	GET_UNAPPROVED_LOADING,
	GET_UNAPPROVED_SUCCESS
} from '../../actionTypes'

const getUnpublished = () => async (dispatch) => {
	dispatch(actionCreator(GET_UNAPPROVED_LOADING))
	try {
		const { data: res } = await Axios.get('https://www.spendwise.ng/api/blog/approve_post/')
		dispatch(actionCreator(GET_UNAPPROVED_SUCCESS, res))
	} catch (err) {
		dispatch(actionCreator(GET_UNAPPROVED_FAILURE, 'Unable to fetch articles to approve'));
	}
}

export default getUnpublished
