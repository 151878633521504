import React from 'react';

import { IoIosArrowUp } from 'react-icons/io';

import classes from './scroll.module.css';

const ScrollToTop = (props) => {
	const [visible, setVisible] = React.useState(false);

	const toggleVisible = () => {
		const scrolled = document.documentElement.scrollTop;
		if (scrolled > 500) {
			setVisible(true);
		} else if (scrolled <= 500) {
			setVisible(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
			/* you can also use 'auto' behaviour
         in place of 'smooth' */
		});
	};

	window.addEventListener('scroll', toggleVisible);

	return (
		<div
			style={{ display: visible ? 'flex' : 'none' }}
			onClick={scrollToTop}
			{...props}
			className={classes.container}
		>
			<IoIosArrowUp />
		</div>
	);
};

export default ScrollToTop;
