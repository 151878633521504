import Axios from 'axios'
import toast from 'react-hot-toast';
import actionCreator from '../../utils/actionCreator'
import {
	USER_REGISTER_FAILURE,
	USER_REGISTER_REQUEST,
	USER_REGISTER_SUCCESS
} from '../actionTypes'

const renderError = (error) => {
	const receivedError = []

	Object.keys(error).forEach((err) => {
		const value = error[err]
		if (Array.isArray(value)) {
			receivedError.push(value[0])
		} else if (typeof value === 'object' && !Array.isArray(value)) {
			receivedError.push((Object.values(value))[0])
		} else {
			receivedError.push(value)
		}
	})
	return receivedError
}

const registerUser = (regdData, action) => async (dispatch) => {
	const {
		username, password, confirmPassword, email, phone, refCode
	} = regdData
	dispatch(actionCreator(USER_REGISTER_REQUEST))
	try {
		const { data: res } = await Axios.post('https://www.spendwise.ng/api/accounts/register/', {
			username,
			password,
			password2: confirmPassword,
			email,
			phone,
			refCode
		})
		dispatch(actionCreator(USER_REGISTER_SUCCESS, res))
		action()
	} catch (err) {
		dispatch(actionCreator(USER_REGISTER_FAILURE, err));
		toast.error(await renderError(err.response.data), { duration: 6000 })
	}
}

export default registerUser;
