import 'es6-shim';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import App from './App';
import setUser from './utils/setUser'
import reportWebVitals from './reportWebVitals';
import configureStore from './store';
import ScrollToTop from './hooks/ScrollToTop'

const store = configureStore()

setUser(store)
ReactDOM.render(
	<Provider store={store}>
		<CookiesProvider>
			<React.StrictMode>
				<BrowserRouter>
					<ScrollToTop />
					<App />
				</BrowserRouter>
			</React.StrictMode>
		</CookiesProvider>
	</Provider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
