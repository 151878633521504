/* eslint-disable block-scoped-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable camelcase */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import Axios from 'axios';
import * as actionTypes from '../../../actionTypes';
import actionCreator from '../../../../utils/actionCreator';

import axiosInstance from '../../../../utils/axios';

export const getAllPosts = (order) =>
	(dispatch) => {
		dispatch(getAllPostsStart());
		axiosInstance
			.get(order === 'created' ? '/blog/all_blogs/?order=date_created' : '/blog/all_blogs/?order=date_published')
			.then((response) => {
				dispatch(getAllPostsSuccess(response.data));
			})
			.catch((error) => {
				if (error.response) {
					dispatch(getAllPostsFailed(error.response.data));
				} else if (error.message) {
					dispatch(
						getAllPostsFailed({
							message: error.message,
						})
					);
				} else {
					dispatch(
						getAllPostsFailed({
							message: 'Error retrieving all blogs',
						})
					);
				}
			});
	};

export const getAllAdminPosts = (order) => async (dispatch) => {
	dispatch(actionCreator(actionTypes.GET_ALL_ADMIN_START))
	try {
		const { data: res } = await Axios.get(order === 'created' ? 'https://www.spendwise.ng/api/blog/admin_get_all_blogs/?order=date_created' : '/blog/admin_get_all_blogs/?order=date_published');
		dispatch(actionCreator(actionTypes.GET_ALL_ADMIN_SUCCESS, res));
	} catch (err) {
		dispatch(actionCreator(actionTypes.GET_ALL_ADMIN_FAILED, 'Unable to fetch all Blog posts at this time'))
	}
};

export const getRecentlyPublished = () => async (dispatch) => {
	dispatch(actionCreator(actionTypes.GET_RECENT_START))
	try {
		const { data: res } = await Axios.get('https://www.spendwise.ng/api/blog/get_f4_blogs/?order=date_published')
		dispatch(actionCreator(actionTypes.GET_RECENT_SUCCESS, res))
	} catch (err) {
		dispatch(actionCreator(actionTypes.GET_RECENT_FAILED, 'Unable to fetch at this time'))
	}
};

export const getAllPostsNextorPrev = (url, isAdmin) =>
	 (dispatch) => {
		dispatch(actionCreator(isAdmin ? actionTypes.GET_ALL_ADMIN_START : actionTypes.GET_ALL_START));

		Axios
			.get(url)
			.then((response) => {
				dispatch(
					actionCreator(
						isAdmin ? actionTypes.GET_ALL_ADMIN_SUCCESS : actionTypes.GET_ALL_SUCCESS,
						response.data
					)
				);
			})
			.catch((error) => {
				if (error.response) {
					dispatch(
						actionCreator(
							isAdmin ? actionTypes.GET_ALL_ADMIN_FAILED : actionTypes.GET_ALL_FAILED,
							error.response.data
						)
					);
				} else if (error.message) {
					dispatch(
						actionCreator(
							isAdmin ? actionTypes.GET_ALL_ADMIN_FAILED : actionTypes.GET_ALL_FAILED,
							error.message
						)
					);
				} else {
					dispatch(
						actionCreator(
							isAdmin ? actionTypes.GET_ALL_ADMIN_FAILED : actionTypes.GET_ALL_FAILED,
							'Error retrieving all posts'
						)
					);
				}
			});
	};

export const getAllPostsStart = () => ({
	type: actionTypes.GET_ALL_START,
});

export const getAllPostsSuccess = (data) => {
	const {
		results, links, total_pages, page_number
	} = data;
	if (links) {
		var { previous, next } = links;
	}

	if (data.message) {
		var { message } = data;
	}

	return {
		type: actionTypes.GET_ALL_SUCCESS,
		results,
		message,
		previous,
		next,
		total_pages,
		page_number,
	};
};

export const getAllPostsFailed = (error) => ({
	type: actionTypes.GET_ALL_FAILED,
	error,
});
