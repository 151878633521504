/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import DOMPurify from 'dompurify';
import {
	TwitterShareButton, FacebookShareButton, TwitterIcon, FacebookIcon
} from 'react-share';
import isEmail from 'validator/lib/isEmail';
import { isEmpty } from 'lodash';
import toast from 'react-hot-toast';
import draftToHtml from 'draftjs-to-html';

import { AiOutlineArrowLeft } from 'react-icons/ai';
import { IoEllipseOutline } from 'react-icons/io5';

import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { withRouter } from 'react-router';

import moment from 'moment';

import { connect } from 'react-redux';
import classes from './postpage.module.scss'
import ArticleBox from '../../../components/customer/Post/ArticleBox';
import AppButton from '../../../components/customer/UI/button';
import CommentBox from '../../../components/customer/CommentBox';
import * as actions from '../../../store/actions';

class SinglePostPage extends Component {
  state = {
  	selectedSlide: 1,
  	browserWidth: window.innerWidth,

  	formValue: {
  		email: {
  			label: 'Email',
  			value: '',
  			valid: false,
  			errorMessage: null,
  		},
  		name: {
  			label: 'Name',
  			value: '',
  			valid: false,
  			errorMessage: null,
  		},
  		comment: {
  			label: 'Reply',
  			value: '',
  			valid: false,
  			errorMessage: null,
  		},
  	},

  	addCommentTitle: 'Leave a Reply',
  	commentToReply: undefined,
  };

  handleResize = () => {
  	this.setState({ browserWidth: window.innerWidth });
  };

	createMarkup = (html) => ({
		__html: DOMPurify.sanitize(html)
	})

	componentDidMount() {
  	// get all posts in case of reload
  	this.props.onGetAllPosts();

  	// get post id
  	const { postid } = this.props.match.params;

  	if (postid) {
  		// call API
  		this.props.onGetPost({
  			id: postid,
  		});
  	}

  	window.addEventListener('resize', this.handleResize);
	}

	componentDidUpdate(prevProps) {
  	const { postid } = this.props.match.params;
  	if (prevProps.match.params !== this.props.match.params) {
  		if (postid) {
  			// call API
  			this.props.onGetPost({
  				id: postid,
  			});

  			window.scrollTo({
  				top: 0,
  				behavior: 'smooth',
  			});
  		}
  	}
	}

	componentWillUnmount() {
  	window.removeEventListener('resize', this.handleResize);
	}

  updateCurrentSlide = (index) => {
  	const { selectedSlide } = this.state;

  	if (selectedSlide !== index) {
  		this.setState({
  			selectedSlide: index,
  		});
  	}
  };

  next = () => {
  	this.setState((state) => ({
  		selectedSlide: state.selectedSlide + 1,
  	}));
  };

  prev = () => {
  	this.setState((state) => ({
  		selectedSlide: state.selectedSlide - 1,
  	}));
  };

  renderArticlesCarousel = () => {
  	const { browserWidth } = this.state;

  	const { getAll } = this.props;

  	return (
  		<div>
  			<div
  				style={{
  					display: 'flex',
  					alignItems: 'center',
  				}}
  			>
  				<div className={classes['slide-control']} onClick={this.prev}>
  					<IoIosArrowBack className='text-xs'/>
  				</div>
  				<div className={classes['slide-control']} onClick={this.next}>
  					<IoIosArrowForward className='text-xs'/>
  				</div>
  			</div>

  			<Carousel
  				showThumbs={false}
  				centerMode={browserWidth > 600}
  				centerSlidePercentage={100 / 3}
  				onChange={this.updateCurrentSlide}
  				showArrows={false}
  				showIndicators={false}
  				showStatus={false}
  				selectedItem={this.state.selectedSlide}
  				// renderItem={this.renderSlideItem}
  			>
  				{getAll
            && getAll.map((each, index) => <ArticleBox key={each.id + index} data={each} />)}
  			</Carousel>
  		</div>
  	);
  };

  // comment

  renderComments = () => {
  	const { singlePostComments } = this.props;

  	const filterComments = singlePostComments.filter((comment) => !comment.in_reply_to)
  	const filterReplies = singlePostComments.filter((comment) => comment.in_reply_to)

  	const modifiedComments = filterComments.map((oneComment) => {
  		const replies = filterReplies.filter((reply) => (oneComment.comment.body === reply.in_reply_to.body)
				&& (oneComment.comment.email === reply.in_reply_to.email));
  		const mod = { ...oneComment, commentReplies: replies }
  		return mod;
  	})
  	console.log('I am', filterComments);
  	console.log('I reply', filterReplies);
  	console.log('modified', modifiedComments);

  	if (!singlePostComments || singlePostComments.length === 0) {
  		return (
  			<div className='text-center text-xs' style={{ color: '#142850' }}>
          This post has no comments yet
  			</div>
  		);
  	}

  	return modifiedComments.map((each) => (
  		<CommentBox handleReply={this.handleReply} key={each.id} data={each} />
  	));
  };

  handleReply = (comment) => {
  	if (comment) {
  		this.commentScrollRef.scrollIntoView({
  			behavior: 'smooth',
  			block: 'end',
  		});
  		this.setState({
  			commentToReply: comment,
  		});
  		this.setState({
  			addCommentTitle: `Reply to ${comment.name}`,
  		});
  	} else {
  		this.setState({ commentToReply: undefined });
  		this.setState({
  			addCommentTitle: 'Leave a Reply',
  		});
  	}
  };

  handleTextChange = (event, key) => {
  	const val = event.target.value;

  	const { formValue } = this.state;
  	formValue[key].value = val;
  	this.setState({ formValue });
  };

  submitComment = (event) => {
  	event.preventDefault();

  	const { onAddComment, singlePost } = this.props;
  	const { formValue, commentToReply } = this.state;

  	if (!isEmail(formValue.email.value)) {
  		toast.error('Please input a valid email');
  		return
  	}

  	const data = {
  		body: formValue.comment.value,
  		name: formValue.name.value,
  		email: formValue.email.value,
  		blog_id: singlePost.id,
  		comment_id: commentToReply ? commentToReply.id : undefined,
  	};

  	onAddComment(data);

  	// clear data
  	formValue.name.value = '';
  	formValue.comment.value = '';
  	formValue.email.value = '';

  	this.setState({ formValue }, () => {
  		// console.log('forms cleared ', formValue);
  	});
  };

  render() {
  	const { addCommentTitle, commentToReply, formValue } = this.state;
  	const { singlePost, singlePostLoading, getAll } = this.props;

  	const postImageUrl = singlePost && singlePost.image;

  	return (
  		<div className={classes['page-wrapper']}>
  			{singlePostLoading
  				? (
  					<div className={classes.loader}>
  					<box-icon name='loader-circle' animation='spin' rotate='90' size='lg' ></box-icon>
  				</div>
  				) : (singlePost
						&& <>
  						<div className='flex items-center cursor-pointer ml-2 md:mt-7'
  							onClick={() => this.props.history.goBack()}
  						>
  							<div className='flex h-5 w-5 justify-center items-center mr-2'
  								style={{
  									borderRadius: 15,
  									backgroundColor: '#051BA9',
  									color: '#fff',
  								}}
  							>
  								<AiOutlineArrowLeft />
  							</div>
  							<span className='text-xs'
  								style={{
  									color: '#051BA9',
  								}}
  							>
							Back
  							</span>
  						</div>
  						<div className={classes['main-page-content']}>
  							<div className={classes['tags-wrapper']}>
  								<div className={classes.tag}>
  									{singlePost.category.charAt(0).toUpperCase() + singlePost.category.slice(1)}
  								</div>
  							</div>
  							<div className={classes['post-title']}>
  								{singlePost.title}
  							</div>
  							<div className={classes['post-misc']}>
  								<div>By {singlePost.author_name.charAt(0).toUpperCase()
							+ singlePost.author_name.slice(1)}</div>
  								<div>
  									<IoEllipseOutline size='10' className='hidden md:block'/>
  									<span>
  										{moment(singlePost.date_created).format('MMMM Do, YYYY')}
  									</span>
  								</div>
  							</div>
  							<div className={classes['social-icons-wrap']}>
  								<div>
  									<TwitterShareButton url={`https://www.spendwise.ng/post/${singlePost.id}`} title={singlePost.title}>
											<TwitterIcon style={{ width: '100%', height: '100%' }}/>
										</TwitterShareButton>
  								</div>
  								<div>
  									<FacebookShareButton url={`https://www.spendwise.ng/post/${singlePost.id}`} quote={singlePost.title}>
											<FacebookIcon style={{ width: '100%', height: '100%' }}/>
										</FacebookShareButton>
  								</div>
  							</div>
  							<div className={classes['featured-image']}>
  								<img src={postImageUrl}
  								/>
  							</div>
  							{singlePost && (
  								<div className={classes['post-content']} dangerouslySetInnerHTML={this.createMarkup(draftToHtml(JSON.parse(singlePost.body)))}></div>
  							)}
  							<div className={classes['comments-wrapper']}>
  								<h2 className={classes.comments}>Comments</h2>
  								{this.renderComments()}
  							</div>
  							<div className={classes['add-comment-wrapper']}>
  								{commentToReply && (
  									<div
  										onClick={() => this.handleReply()}
  										style={{
  											fontSize: 20,
  											fontWeight: 'bold',
  											cursor: 'pointer',
  											float: 'right',
  										}}
  									>
									x
  									</div>
  								)}

  								<h2>{addCommentTitle}</h2>

  								<form>
  									<div>
  										<label>Email Address</label>
  										<br />
  										<input
  											onChange={(event) => this.handleTextChange(event, 'email')}
  											type="email"
  											name="email"
												required
  											value={formValue.email.value}
  										/>
  									</div>
  									<div>
  										<label>Name</label>
  										<br />
  										<input
  											onChange={(event) => this.handleTextChange(event, 'name')}
  											type="text"
  											name="name"
												required
  											value={formValue.name.value}
  										/>
  									</div>
  									<div>
  										<label>Type your reply</label>
  										<br />
  										<textarea
  											value={formValue.comment.value}
  											onChange={(event) => this.handleTextChange(event, 'comment')}
  											rows={3}
												required
  										></textarea>
  									</div>

  									<div
  										// eslint-disable-next-line no-return-assign
  										ref={(ref) => (this.commentScrollRef = ref)}
  										style={{
  											marginTop: 15,
  											fontSize: 13,
  											display: 'flex',
  											alignItems: 'center',
  										}}
  									>
  										<input type="checkbox"/>
									Would you like to save your details
  									</div>
  									<AppButton
  										clicked={this.submitComment}
  										styles={{
  											width: '100%'
  										}}
											disable={isEmpty(formValue.comment.value)
											|| isEmpty(formValue.name.value)
											|| isEmpty(formValue.email.value)}
  										title="Post your comment"
  									/>
  								</form>
  							</div>
								{getAll && getAll.length > 1 && (
									<div className={classes['other-articles-wrapper']}>
										<h2 style={{ textAlign: 'left', marginLeft: 18 }}>Other Articles</h2>
										<div
											style={{
												marginTop: 15,
												marginBottom: 20,
											}}
										>
											{this.renderArticlesCarousel()}
										</div>
									</div>
								)}
  						</div>
  					</>
  				)
  			}
  		</div>
  	);
  }
}

const mapDispatchToProps = (dispatch) => ({
	onGetAllPosts: () => dispatch(actions.getAllPosts()),
	onGetPost: (data) => dispatch(actions.getSinglePost(data)),
	//
	onAddComment: (data) => dispatch(actions.addComment(data)),
});

const mapStateToProps = (state) => {
	const {
		singlePost,
		singlePostError,
		singlePostLoading,
		getAll,
		singlePostComments,
	} = state.postsReducer;

	return {
		singlePost,
		singlePostError,
		singlePostLoading,
		getAll,
		singlePostComments,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(SinglePostPage));
