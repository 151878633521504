import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { FaChevronDown, FaChevronUp, FaCheck } from 'react-icons/fa'
import useOnClickOutside from '../../hooks/onClickOutside'
import styles from './dropdown.module.scss'

const Dropdown = ({ options = [], size }) => {
	const ref = useRef()
	const [open, setOpen] = useState(false)
	const [title, setTitle] = useState(
		options.length ? options[0].name : 'No Selection'
	)

	useOnClickOutside(ref, () => setOpen(false))

	return (
		<div className={`${styles.dropdown} ${size === 'medium' && styles.medium}`}>
			<button className={styles.mainOne} onClick={() => setOpen((prev) => !prev)}>
				<span>{title}</span>
				<>
					{open ? (
						<FaChevronUp className={styles.chev} />
					) : (
						<FaChevronDown className={styles.chev} />
					)}
				</>
			</button>
			{open && (
				<div className={styles.items} ref={ref}>
					{options.map((option, i) => (
						<button
							key={i}
							className={styles.item}
							onClick={() => {
								setTitle(option.name)
								// eslint-disable-next-line no-unused-expressions
								option.action && option.action()
								setOpen(false)
							}}
						>
							<span>{ (title === option.name) && <FaCheck size='10'/>}</span>
							{option.name}
						</button>
					))}
				</div>
			)}
		</div>
	)
}

Dropdown.propTypes = {
	options: PropTypes.array,
	size: PropTypes.string
}

export default Dropdown
