import {
	UNPUBLISH_POSTS_FAILURE,
	UNPUBLISH_POSTS_LOADING,
	UNPUBLISH_POSTS_SUCCESS
} from '../../actionTypes'

const initialState = {
	response: null,
	loading: false,
	error: null
}

export default function unpublishBlogReducer(state = initialState, action) {
	switch (action.type) {
	case UNPUBLISH_POSTS_LOADING:
		return { ...state, loading: true };
	case UNPUBLISH_POSTS_FAILURE:
		return { ...state, loading: false, error: action.payload };
	case UNPUBLISH_POSTS_SUCCESS:
		return {
			...state,
			loading: false,
		};
	default:
		return state;
	}
}
