import * as actionTypes from '../../actionTypes';

const initialState = {
	successMsg: undefined,
	error: undefined,
	loading: false,
};

const newsLetterReducer = (state = initialState, action) => {
	switch (action.type) {
	// clear
	case actionTypes.CLEAR_SUBS_MESSAGES:
		return initialState
		//
	case actionTypes.SUBSCRIBE_START:
		return {
			...state,
			successMsg: undefined,
			error: undefined,
			loading: true,
		};
	case actionTypes.SUBSCRIBE_SUCCESS:
		return {
			...state,
			successMsg: action.message,
			loading: false,
		};
	case actionTypes.SUBSCRIBE_FAILED:
		return {
			...state,
			loading: false,
			error: action.error,
		};
	default:
		return state;
	}
};

export default newsLetterReducer
