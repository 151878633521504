import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';

import { FaBell } from 'react-icons/fa';
import { IoMdArrowDropdown } from 'react-icons/io';
import { logout } from '../../../store/actions/blogin.action'
import classes from '../../admin/Nav/Nav.module.scss';

// import navImg from '../../../assets/admin/nav-bar.png';

const Nav = () => {
	const history = useHistory();
	const dispatch = useDispatch()
	const [name, setName] = useState('');

	const signOut = () => {
		dispatch(logout())
		history.push('/blogin');
		return history.go(0)
	};

	// getting all blogs
	useEffect(() => {
		// get user token from saved storage
		const userToken = JSON.parse(localStorage.getItem('user'));

		setName(userToken.username);
	}, []);

	const userInitial = name.slice(0, 2).toUpperCase();

	return (
		<nav>
			{/* <img src={navImg} alt="img" /> */}

			<div className={classes['nav-description']}>
				<FaBell id={classes.bell} />

				<div>
					<h1>{userInitial}</h1>

					<div className={classes.dropdown}>
						<h2>{name}</h2>
						<h3>Content Writer</h3>
						<div className={classes.dropdownContent}>
							<a href="#" onClick={signOut}>Sign out</a>
						</div>
					</div>

					<IoMdArrowDropdown size={24} />
				</div>
			</div>
		</nav>
	);
};

export default Nav;
