/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'

// import axios for fetching apis
import Axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import classes from './Create.module.scss';

import addPhoto from '../../../../assets/admin/Photo.png';
import closeIcon from '../../../../assets/admin/close.png';

const Create = ({ onClose, open, details }) => {
	const [image, setImage] = useState(null);
	const [title, setTitle] = useState('');
	const [isDraft, setIsDraft] = useState(false);
	const [category, setCategory] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [preview, setPreview] = useState(null);
	const [createdMessage, setCreatedMessage] = useState('');
	const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
	const [convertedContent, setConvertedContent] = useState(null);

	const userToken = JSON.parse(localStorage.getItem('authToken'));

	useEffect(() => {
		if (details?.draft) {
			setCategory(details.category)
			setTitle(details.title)
			setIsDraft(details.is_draft)
			setConvertedContent(details.draft)
			setEditorState(EditorState.createWithContent(
				convertFromRaw(JSON.parse(details.draft))
			))
		}
	}, [details])

	const onclose = (e) => {
		setImage(null)
		setTitle('')
		setIsDraft(false)
		setCategory('')
		setIsSubmitting(false)
		setPreview(null)
		setCreatedMessage('')
		setEditorState(() => EditorState.createEmpty(),)
		setConvertedContent(null)
		onClose(e);
	};

	const convertForStorage = () => {
		const contentState = editorState.getCurrentContent()
		const note = { content: convertToRaw(contentState) }
		note.content = JSON.stringify(note.content)
		setConvertedContent(note.content);
	}

	const handleEditorChange = (state) => {
		setEditorState(state);
		convertForStorage();
	}

	const confirmTextIsTyped = () => {
		const insideEditor = convertToRaw(editorState.getCurrentContent());
		const blockLength = insideEditor.blocks.length;
		let aggregatedText = '';
		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < blockLength; i++) {
			const seenText = insideEditor.blocks[i].text;
			// eslint-disable-next-line no-unused-expressions
			aggregatedText += seenText;
		}
		if (aggregatedText === '') {
			return false;
		}
		return true
	}

	// On file select (from the pop up)
	const onFileChange = (e) => {
		if (e.target.files && e.target.files[0]) {
			setImage(e.target.files[0]);
			setPreview(URL.createObjectURL(e.target.files[0]));
		}
	};

	// Updating a draft post
	const updateDraft = (e) => {
		e.preventDefault();
		setIsSubmitting(true);

		const formData = new FormData();
		formData.append('title', title);
		formData.append('draft', convertedContent);
		formData.append('category', category);
		formData.append('is_draft', 'yes');
		formData.append('blog_id', details.id);
		if (image) {
			formData.append('image', image, image.name)
		}

		Axios.patch('https://www.spendwise.ng/api/blog/get_update_draft/',
			formData,
			{
				headers: {
					Authorization: `Token ${userToken}`
				}
			})
			.then((res) => {
				if (res.status === 200) {
					setIsSubmitting(false);
					toast.success('Draft updated successfully')
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				}
			})
		// eslint-disable-next-line no-unused-vars
			.catch((error) => {
				setIsSubmitting(false);
				toast.error('Please supply an image not more than 1mb, If problem persists, contact Admin', { duration: 5000 });
			});
	};

	// creating a blog post
	const createBlogPost = (e) => {
		e.preventDefault();
		setIsSubmitting(true);

		const formData = new FormData();
		formData.append('title', title);
		formData.append('body', convertedContent);
		formData.append('category', category);
		if (image) {
			formData.append('image', image, image.name)
		}
		if (isDraft) {
			formData.append('is_draft', 'yes');
		}
		if (details) {
			formData.append('blog_id', details.id);
		}

		if (image || details) {
			Axios.post('https://www.spendwise.ng/api/blog/create/',
				formData,
				{
					headers: {
						Authorization: `Token ${userToken}`
					}
				})
				.then((res) => {
					if (res.status === 200) {
						setIsSubmitting(false);
						toast.success('Post created successfully')
						setTimeout(() => {
							window.location.reload();
						}, 2000);
					}
				})
				// eslint-disable-next-line no-unused-vars
				.catch((error) => {
					setIsSubmitting(false);
					toast.error('Please supply an image not more than 1mb, If problem persists, contact Admin', { duration: 5000 });
				});
		} else {
			setIsSubmitting(false);
			toast.error('You need to select an Image');
		}
	};

	if (!open) {
		return null;
	}

	return (
		<div className={classes['create-container']}>
			<Toaster containerStyle={{ fontWeight: 'bold', fontSize: 12 }}/>
			<div className={classes['create-content']}>
				<img src={closeIcon} alt="img" id={classes.closeIcon} onClick={onclose} />

				<center>
					<h1>
						{((window.location.pathname === '/blogger/draft') || details?.is_draft) ? 'Continue Writing'
							: 'New Blog Post'}
					</h1>
					<p>All content created
						are added to be review by the admin
						and are made available to customers when approved/published.
					</p>
				</center>

				<div className={classes['img-container']}>
					<label htmlFor="img-button">
						{preview ? (
							<img className={classes.imgItself} src={preview} alt="img" />
						) : (details && details.image !== '') ? (
							<img className={classes.imgItself} src={details.image} alt="img" />
						) : (
							<img src={addPhoto} alt="img" />
						)}
					</label>
					<input
						type="file"
						id='img-button'
						accept="image/jpg, image/jpeg, image/png"
						name="image"
						onChange={onFileChange}
						style={{ display: 'none' }}
					/>
				</div>

				<form action="">
					<label htmlFor="title">
						Title
						<input
							type="text"
							name="title"
							defaultValue={title}
							onChange={(e) => setTitle(e.target.value)}
							placeholder="Enter Title"
							required
						/>
					</label>

					<label htmlFor="img">
						Upload Header Image
						<input
							type="file"
							accept="image/jpg, image/jpeg, image/png"
							name="image"
							onChange={onFileChange}
						/>
					</label>

					<label htmlFor="category" className={classes['input-category']}>
						Select Category
						<select
							name="category"
							defaultValue={details && details.category}
							id=""
							onChange={(e) => setCategory(e.target.value)}
							style={{
								border: 'revert'
							}}
						>
							<option value="" />
							<option value="save">Save</option>
							<option value="manage">Manage</option>
							<option value="keep">Keep</option>
							<option value="make">Make</option>
							<option value="debt">Debt</option>
							<option value="protect">Protect</option>
							<option value="grow">Grow</option>
						</select>
					</label>

					<label>
						<Editor
							editorState={editorState}
							onEditorStateChange={handleEditorChange}
							wrapperClassName={classes['wrapper-class']}
							editorClassName={classes['editor-class']}
							toolbarClassName={classes['toolbar-class']}
						/>
					</label>
				</form>

				<ol className={classes.switches}>
					<li>
						<input
							type="checkbox"
							id="createBlog"
							defaultChecked={isDraft}
							onChange={(e) => setIsDraft(e.target.checked)}
						/>
						<label htmlFor="createBlog">
							<span />
							Draft this post
						</label>
					</li>
				</ol>

				{createdMessage ? <center style={{ fontSize: 14, color: 'red' }}>{createdMessage}</center> : ''}
				<div className={classes.btnParent}>
					<button onClick={onclose}>Cancel</button>
					<button
						onClick={details && details.is_draft && isDraft ? updateDraft : createBlogPost}
						disabled={!title || !category || (convertedContent === null) || !confirmTextIsTyped()}
					>
						{!isSubmitting ? isDraft ? (
							'Draft'
						) : (
							'Submit & Publish'
						) : (
							<box-icon name='loader-circle' animation='spin' rotate='90' size='sm' color='white'></box-icon>
						)}
					</button>
				</div>
			</div>
		</div>
	);
};

Create.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool,
	details: PropTypes.object
}

export default Create;
