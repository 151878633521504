/* eslint-disable react/jsx-key */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

import { FiArrowRightCircle } from 'react-icons/fi';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { RiArrowRightFill } from 'react-icons/ri';
// import { FaCaretDown } from 'react-icons/fa'

import { Carousel } from 'react-responsive-carousel';
import classes from './home.module.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import borrowIcon from '../../../assets/icons/borrow.png';
import protectIcon from '../../../assets/icons/protect.png';
import retireIcon from '../../../assets/icons/retire.png';
import saveIcon from '../../../assets/icons/save.png';
import lifeUloveIcon from '../../../assets/icons/lifeUlove.png';
import makeIcon from '../../../assets/icons/make.png';
import growIcon from '../../../assets/icons/grow.png';
import playStoreIcon from '../../../assets/icons/playstore.png';

import * as actions from '../../../store/actions';

import ScrollToTop from '../../../components/customer/scrollToTop';
import PostBox from '../../../components/customer/postBox';

class Home extends Component {
  state = {
  	categories: [
  		{
  			title: 'Manage Money',
  			bg: '#4977EF',
  			theme: 'light',
  			link: 'manage',
  			desc: 'Learn how to track your expenses and spending patterns.',
  		},
  		{
  			title: 'Keep Money',
  			bg: '#8BA8F5',
  			link: 'keep',
  			desc: 'Learn how to budget automatically with ease.',
  		},
  		{
  			title: 'Make Money',
  			bg: '#19B5DE',
  			link: 'make',
  			desc: 'How about more ways and side hustles to make extra income.',
  		},
  		{
  			title: 'Borrow Money',
  			bg: '#061737',
  			theme: 'light',
  			link: 'repay',
  			desc: ' Learn how to repay debt with ease and become debt free.',
  		},
  		{
  			title: 'Protect Money',
  			bg: '#0E38A4',
  			theme: 'light',
  			link: 'protect',
  			desc: 'See how you can safeguard your money and other valuables.',
  		},
  		{
  			title: 'Grow Money',
  			bg: '#E7EDFD',
  			link: 'grow',
  			desc: 'Learn how to invest and multiply your money.',
  		},
  	],

  	iconCat: [
  		{
  			title: 'Goals',
  			bg: '#4977EF',
  			icon: saveIcon,
  			link: 'save',
  		},
  		{
  			title: 'Manage',
  			bg: '#19B5DE',
  			link: 'manage',
  			icon: borrowIcon,
  		},
  		{
  			title: 'Keep',
  			bg: '#4977EF',
  			icon: makeIcon,
  			link: 'keep',
  		},
  		{
  			title: 'Protect',
  			bg: '#061737',
  			icon: protectIcon,
  			link: 'protect',
  		},
  		{
  			title: 'Debt',
  			bg: '#0E38A4',
  			icon: retireIcon,
  			link: 'repay',
  		},
  		{
  			title: 'Make',
  			bg: '#E7EDFD',
  			icon: lifeUloveIcon,
  			link: 'make',
  		},
  		{
  			title: 'Grow',
  			bg: '#8BA8F5',
  			icon: growIcon,
  			link: 'grow',
  		},
  	],
  	// for carousel
  	selectedSlide: 1,
  	browserWidth: window.innerWidth,

  	// newsletter
  	newsletterEmail: '',
  	newsletterValid: false,

  	//
  	where2Start: [
  		{ text: 'I want to know my spending patterns', category: 'manage' },
  		{ text: 'I want to make more money', category: 'make' },
  		{ text: "I'm trying to save ", category: 'goals' },
  		{ text: "I'm seeking to invest", category: 'grow' },
  		{ text: "I'm trying to spend less", category: 'keep' },
  		{ text: "I'm trying to pay off debt  ", category: 'debt' },
  		{ text: 'I want to better manage my credit', category: 'debt' },
  		{ text: 'I have assets I will like to acquire', category: 'grow' },
  		{ text: "I'm concerned about retirement", category: 'grow' },
  		{ text: 'I want to be prepared for emergencies', category: 'protect' },
  	],
  	selectedDropdown: 'manage',
  };

  handleResize = () => {
  	this.setState({ browserWidth: window.innerWidth });
  };

  componentDidMount() {
  	// get all posts
  	this.props.onGetPosts()
  	this.props.onGetRecentPub()
  	// clear all newsletter messages
  	this.props.onClearSubsMsg()

  	window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
  	window.removeEventListener('resize', this.handleResize);
  }

  /**
   * CAROUSEL CONTROLS
   */
  updateCurrentSlide = (index) => {
  	const { selectedSlide } = this.state;

  	if (selectedSlide !== index) {
  		this.setState({
  			selectedSlide: index,
  		});
  	}
  };

  nextSlide = () => {
  	this.setState((state) => ({
  		selectedSlide: state.selectedSlide + 1,
  	}));
  };

  prevSlide = () => {
  	this.setState((state) => ({
  		selectedSlide: state.selectedSlide - 1,
  	}));
  };

  renderCarouselControls = () => (
  	<div className='flex justify-between'>
  		<div className={classes['slide-control']} onClick={this.prevSlide}>
  			<IoIosArrowBack />
  		</div>
  		<div className={classes['slide-control']} onClick={this.nextSlide}>
  			<IoIosArrowForward />
  		</div>
  	</div>
  );

  renderPostsCarousel = (props) => {
  	const { browserWidth } = this.state;

  	const { recentlyPublished } = props;

  	return (
  		<div className={classes['carousel-wrapper']}>
  			<Carousel
  				showThumbs={false}
  				centerMode={browserWidth > 600}
  				centerSlidePercentage={100 / 3}
  				// centerSlidePercentage={browserWidth > 600 && browserWidth < 800 ? 100 / 2 : 100 /3}
  				onChange={this.updateCurrentSlide}
  				showArrows={false}
  				showIndicators={false}
  				showStatus={false}
  				selectedItem={this.state.selectedSlide}
  				// renderItem={this.renderSlideItem}
  			>
  				{recentlyPublished
  						&& recentlyPublished.map((each, index) => (
  							<PostBox
  								key={each + index}
  								data={each}
  							/>
  						))
  				}
  			</Carousel>
  		</div>
  	);
  };

  renderViewTopicButton = (link, theme) => (
  	<Link
  		to={`/category/${link}`}
  		style={{
  			textDecoration: 'none',
  			color: theme ? (theme === 'light' ? 'white' : 'black') : 'black',
  		}}
  	>
  		<div
  			className={classes['view-topics-button']}
  			style={{
  				backgroundColor: theme
  					? theme === 'light'
  						? 'rgba(255, 255, 255, 0.15)'
  						: 'rgba(28, 47, 70, 0.15)'
  					: 'rgba(28, 47, 70, 0.15)',
  			}}
  		>
  			<span className='text-xs'>View Topics</span>
  			<span style={{ marginBottom: -3 }}>
  				<FiArrowRightCircle />
  			</span>
  		</div>
  	</Link>
  );

  submitEmailforNewsletter = () => {
  	const { newsletterEmail } = this.state;

  	if (!newsletterEmail || newsletterEmail.trim() === '') {
  		toast.error('Please input newsletter email');
  		return;
  	}
  	this.props.onSubscribe({
  		email: this.state.newsletterEmail,
  		page_url: window.location.href
  	});

  	// clear input after submit
  	setTimeout(() => {
  		this.setState({ newsletterEmail: '' });
  	}, 500);
  };

  handleTextChange = (event) => {
  	this.setState({ newsletterEmail: event.target.value });
  	this.props.onClearSubsMsg();
  };

  handleDropdownChange = (event) => {
  	this.setState({
  		selectedDropdown: event.target.value,
  	});
  };

  /**
   * NEXT AND PREV GET POSTS API
   */
  renderNextandPrevPostButtons = () => {
  	const {
  		getAllNextUrl,
  		getAllPrevUrl,
  		onGetPostsNextSlideorPrevSlide,
  		//
  		getAllTotalPages,
  		getAllPageNumber,
  	} = this.props;

  	return (
  		<div
  			style={{
  				display: 'flex',
  				justifyContent: 'space-between',
  			}}
  			className={classes['pagination-wrapper']}
  		>
  			<div>
  				{getAllPageNumber} of {getAllTotalPages}{' '}
  			</div>
  			<div className={classes['grid-navigation-wrapper']}>
  				<div
  					onClick={
  						getAllPrevUrl
  							? () => onGetPostsNextSlideorPrevSlide(getAllPrevUrl)
  							: undefined
  					}
  					style={
  						!getAllPrevUrl
  							? { backgroundColor: 'rgba(27, 91, 172, 0.2)' }
  							: {}
  					}
  				>
  					<IoIosArrowBack size='1rem'/>
  				</div>
  				<div
  					onClick={
  						getAllNextUrl
  							? () => onGetPostsNextSlideorPrevSlide(getAllNextUrl)
  							: undefined
  					}
  					style={
  						!getAllNextUrl
  							? { backgroundColor: 'rgba(27, 91, 172, 0.2)' }
  							: {}
  					}
  				>
  					<IoIosArrowForward size='1rem'/>
  				</div>
  			</div>
  		</div>
  	);
  };

  /**
   *
   * MAIN PAGE CONTENT
   */

  render() {
  	const { categories } = this.state;

  	const {
  		getAllMessage,
  		getAllError,
  		getAllLoading,
  		getAll,
  		loading,
  		newsletterError,
  		newsletterLoading,
  		newsletterSuccess,
  		recentlyPublished,
  		getAllNextUrl,
  		getAllPrevUrl,
  	} = this.props;

  	return (
  		<div className={classes['content-container']}>
  			<Toaster containerStyle={{ fontWeight: 'bold', fontSize: 12 }}/>
  			<div className={classes.banner}>
  				<div className={classes['banner-content']}>
  					<div className={classes.bannerHead}>
  						<span></span>
  						<div className={classes.quote}>
                ‘You must gain control over your money or the lack of it will
                forever control you.’
  						</div>
  					</div>
  					<div className='text-sm font-bold ml-5 md:ml-9'
  						style={{
  							fontFamily: 'Inter',
  							color: '#FFAA00',
  						}}
  					>
              Dave Ramsey
  					</div>
  					<div className='bg-black rounded-xl h-12 w-32 mt-7 ml-8'>
							 <a href='https://play.google.com/store/apps/details?id=com.spendwise.spendwiseapp' target='_blank' rel='noreferrer'>
  						<div className='flex justify-center items-center p-2 text-white gap-2'>
  							<img src={playStoreIcon} />
  							<div>
  								<div style={{ fontSize: 8 }}>Get it on</div>
  								<div style={{ fontSize: 14, fontWeight: 'bold' }}> Google Play</div>
  							</div>
  						</div>
  						</a>
  					</div>
  				</div>
  			</div>
  			<div className={classes['grid-container']}>
  				<div className='flex flex-col justify-around box-border'
  					style={{ backgroundColor: '#F8F9FB' }}
  				>
  					<div className={classes['category-details-wrapper']}>
  						<div className='text-sm font-bold mb-3 pt-2'
  							style={{ color: '#061737' }}
  						>
                SAVE MONEY
  						</div>
  						<div className={`text-xs ${classes['category-details']}`}>
                Find out how to use goal-targeted savings to save with ease.
  						</div>
  					</div>
  					<div style={{ marginTop: 15, marginBottom: 20 }}>
  						{this.renderViewTopicButton('save')}
  					</div>
  				</div>
  				<div className='flex flex-row flex-wrap box-border'>
  					{categories.map((each, index) => (
  						<div
  							key={each.title + index}
  							className={classes['category-right']}
  							style={{
  								backgroundColor: each.bg,
  								color: each.theme
  									? each.theme === 'light'
  										? 'white'
  										: '#061737'
  									: '#061737',
  							}}
  						>
  							<div className={classes['category-details-wrapper']}>
  								<div className='text-sm font-bold mb-3 pt-2'>
  									{each.title}
  								</div>
  								<div className={`text-xs ${classes['category-details']}`}>
  									{each.desc}
  								</div>
  							</div>
  							<div>
  								{this.renderViewTopicButton(
  									each.link,
  									each.theme ? each.theme : undefined
  								)}
  							</div>
  						</div>
  					))}
  				</div>
  			</div>
  			<div className={classes['call-to-action']}>
  				<div className={classes['cta-heading']}>
            Not sure where to start? <br /> It depends on where you are.{' '}
  				</div>
  				<div className={classes['cta-input-wrapper']}>
  					<select onChange={this.handleDropdownChange} name="cta" id="cta">
  						{this.state.where2Start.map((each, index) =>
  							<option key={index} value={each.category}>{each.text}</option>)}
  					</select>
  				</div>
  				<Link
  					to={`/category/${this.state.selectedDropdown}`}
  					style={{
  						textDecoration: 'none',
  					}}
  				>
  					<button>Get started</button>
  				</Link>
  			</div>
  			{recentlyPublished && recentlyPublished.length > 2 && (
  				<div className={classes['latest-articles']}>
  					{loading ? (
  						<div className={classes.loader}>
  							<box-icon name='loader-circle' animation='spin' rotate='90' size='lg' ></box-icon>
  						</div>
  					) : (
  						<>
  							<div className={classes.here}>
  								<div
  									className={classes.heading}>
								See our latest Articles
  								</div>

  								{/* <div className={classes['lg-slider-control']}>
  									{this.renderCarouselControls()}
  								</div> */}
  							</div>
  							<div>{this.renderPostsCarousel(this.props)}</div>
  							<div className={'left-0 right-0 w-full absolute top-44'}>
  								{this.renderCarouselControls()}
  							</div>
  						</>
  					)}
  				</div>
  			)}
  			{getAll && getAll.length > 0 && (
  				<div className={classes['trending-articles']}>
  					<div className={classes.trendhead}>TRENDING ARTICLES</div>
  					{getAllLoading ? (
  						<div className={classes.loader}>
  							<box-icon name='loader-circle' animation='spin' rotate='90' size='lg' ></box-icon>
  						</div>
  					) : (
  						<div className={classes['articles-grid-wrapper']}>
  							{getAll
									&& getAll.map((each, index) =>
										<PostBox key={each + index}
											data={each} id={index} />)}
  						</div>

  					)}
  					{(getAllNextUrl || getAllPrevUrl) && this.renderNextandPrevPostButtons()}
  				</div>
  			)}
  			<div
  				className={classes.inSub}
  				style={{
  					minHeight: 250
  				}}
  			>
  				<div className={classes['newsletter-dotted-image']} />
  				<div className={classes['newsletter-card']}>
  					<div className={classes['newsletter-text']}>
              Subscribe for newsletter
  					</div>
  					<div className={classes.newsletterWrap}>
  						<div className={classes['newsletter-input-wrap']}>
  							<input
  								value={this.state.newsletterEmail}
  								onChange={this.handleTextChange}
  								type="text"
  								placeholder="Enter email address"
  							/>

  							<div
  								onClick={this.submitEmailforNewsletter}
  								style={{
  									backgroundColor: '#fff',
  									height: 40,
  									width: 40,
  									borderRadius: 20,
  									display: 'flex',
  									justifyContent: 'center',
  									alignItems: 'center',
  									cursor: 'pointer',
  								}}
  							>
  								{newsletterLoading ? (
  									<box-icon name='loader-circle' animation='spin' rotate='90' size='sm'></box-icon>
  								)
  								: <RiArrowRightFill
  									style={{
  										color: '#1B7DBF',
  									}}
  								/>}
  							</div>
  						</div>

  						{newsletterError && (
  							<div className={classes['newsletter-error']}>
  								{newsletterError.message}
  							</div>
  						)}
  					</div>
  				</div>
  			</div>

  			<div className={classes['icons-wrapper']}>
  				{this.state.iconCat.map((each) => (
  					<div key={each.title}>
  						<Link to={`/category/${each.link}`}>
  							<div className={classes['category-icon-container']}>
  								<img alt={`${each.title}category icon `} src={each.icon} />
  							</div>
  						</Link>
  						<div className={classes['icon-title']}>{each.title}</div>
  					</div>
  				))}
  			</div>

  			<ScrollToTop />
  		</div>
  	);
  }
}

const mapStateToProps = (state) => {
	const {
		getAllMessage,
		getAllError,
		getAllLoading,
		getAll,
		getAllNextUrl,
		getAllPrevUrl,
		getAllTotalPages,
		getAllPageNumber,
		recentlyPublished,
		loading,
	} = state.postsReducer;

	const newsletterLoading = state.newsLetterReducer.loading;
	const newsletterError = state.newsLetterReducer.error;
	const newsletterSuccess = state.newsLetterReducer.successMsg;

	return {
		getAllMessage,
		getAllError,
		getAllLoading,
		getAll,
		getAllNextUrl,
		getAllPrevUrl,
		getAllTotalPages,
		getAllPageNumber,
		recentlyPublished,
		loading,
		newsletterError,
		newsletterLoading,
		newsletterSuccess,
	};
};

const mapDispatchToProps = (dispatch) => ({
	onGetPosts: () => dispatch(actions.getAllPosts()),
	onGetRecentPub: () => dispatch(actions.getRecentlyPublished()),
	onGetPostsNextSlideorPrevSlide: (data) => dispatch(actions.getAllPostsNextorPrev(data)),
	onSubscribe: (data) => dispatch(actions.subscribe(data)),
	//
	onClearSubsMsg: () => dispatch(actions.clearAllSubsMessages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
