import * as actionTypes from '../../actionTypes';

const initialState = {
	// SINGLE POST
	singlePost: undefined,
	singlePostError: undefined,
	singlePostLoading: false,
	singlePostComments: undefined,
	// ALL POSTS
	getAllMessage: '',
	getAllError: {},
	getAllLoading: false,
	getAll: [],
	getAllNextUrl: '',
	getAllPrevUrl: '',
	getAllTotalPages: undefined,
	getAllPageNumber: undefined,

	// RECENTLY PUBLISHED
	recentlyPublished: [],
	error: {},
	loading: false,

	// POSTS BY CATEGORY
	getAllByCat: [],
	getAllByCatLoading: false,
	getAllByCatError: undefined,
	getAllByCatMessage: undefined,
	getAllByCatNext: undefined,
	getAllByCatPrev: undefined,
	getAllByCatTotalPages: undefined,
	getAllByCatPageNumber: undefined,

	//
	likeUnlikeLoading: false,
	likeUnlikeError: undefined,
};

const postsReducer = (state = initialState, action) => {
	switch (action.type) {
	//   ALL POSTS
	case actionTypes.GET_ALL_START:
		return {
			...state,
			getAllLoading: true,
		};
	case actionTypes.GET_ALL_SUCCESS:
		return {
			...state,
			getAllError: null,
			getAllMessage: action.message,
			getAllLoading: false,
			getAll: action.results,
			getAllPrevUrl: action.previous,
			getAllNextUrl: action.next,
			getAllTotalPages: action.total_pages,
			getAllPageNumber: action.page_number,
		};
	case actionTypes.GET_ALL_FAILED:
		return {
			...state,
			getAllError: action.error,
			getAllLoading: false,
		};

	// RECENTLY PUBLISHED
	case actionTypes.GET_RECENT_START:
		return { ...state, loading: true };
	case actionTypes.GET_RECENT_SUCCESS:
		return { ...state, loading: false, recentlyPublished: action.payload };
	case actionTypes.GET_RECENT_FAILED:
		return { ...state, loading: false, error: action.payload };

		//   POSTS BY CATEGORY
	case actionTypes.GET_ALL_BY_CAT_START:
		return {
			...state,
			getAllByCatLoading: true,
		};
	case actionTypes.GET_ALL_BY_CAT_SUCCESS:
		return {
			...state,
			getAllByCatMessage: action.message,
			getAllByCatLoading: false,
			getAllByCat: action.results,
			getAllByCatPrev: action.previous,
			getAllByCatNext: action.next,
			getAllByCatTotalPages: action.total_pages,
			getAllByCatPageNumber: action.page_number,
		};
	case actionTypes.GET_ALL_BY_CAT_FAILED:
		return {
			...state,
			getAllByCatError: action.error,
			getAllByCat: [],
			getAllByCatLoading: false,
		};

		//   LIKE OR UNLIKE POST
	case actionTypes.LIKE_POST_START:
		return {
			...state,
			likeUnlikeLoading: true,
		};
	case actionTypes.LIKE_POST_SUCCESS:
		return {
			...state,
			likeUnlikeLoading: false,
		};
	case actionTypes.LIKE_POST_FAILED:
		return {
			...state,
			likeUnlikeLoading: false,
			likeUnlikeError: action.error,
		};

		//   SINGLE POST
	case actionTypes.GET_SINGLE_POST_START:
		return {
			...state,
			singlePostLoading: true,
		};
	case actionTypes.GET_SINGLE_POST_SUCCESS:
		return {
			...state,
			singlePostLoading: false,
			singlePost: action.blogData,
			singlePostComments: action.comments,
		};
	case actionTypes.GET_SINGLE_POST_FAILED:
		return {
			...state,
			singlePostLoading: false,
			singlePostError: action.error,
		};
	default:
		return state;
	}
};

export default postsReducer
