/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-use-before-define */
import toast from 'react-hot-toast';
import * as actionTypes from '../../../actionTypes';
import axiosInstance from '../../../../utils/axios';

export const subscribe = (data) =>
	(dispatch) => {
		dispatch(subscribeStart());
		axiosInstance
			.post('/blog/subscribe/', data)
			.then((response) => {
				dispatch(subscribeSuccess(response.data));
				toast.success(response.data.message)
			})
			.catch((error) => {
				if (error.response) {
					dispatch(subscribeFailed(error.response.data));
				} else if (error.message) {
					dispatch(
						subscribeFailed({
							message: error.message,
						})
					);
				} else {
					dispatch(
						subscribeFailed({
							message: 'Error while Subscribing',
						})
					);
				}
			});
	};
export const subscribeStart = () => ({
	type: actionTypes.SUBSCRIBE_START,
});

export const subscribeSuccess = (data) => {
	const { message } = data;
	return {
		type: actionTypes.SUBSCRIBE_SUCCESS,
		message,
	};
};

export const subscribeFailed = (error) => ({
	type: actionTypes.SUBSCRIBE_FAILED,
	error,
});

export const clearAllSubsMessages = () => ({
	type: actionTypes.CLEAR_SUBS_MESSAGES,
});
