import * as actionTypes from '../../actionTypes';

const initialState = {
	// ALL POSTS
	getAllError: {},
	getAllLoading: false,
	getAll: [],
	getAllNextUrl: '',
	getAllPrevUrl: '',
	getAllTotalPages: undefined,
	getAllPageNumber: undefined,
};

const allBlogAdmin = (state = initialState, action) => {
	switch (action.type) {
	case actionTypes.GET_ALL_ADMIN_START:
		return {
			...state,
			getAllLoading: true,
		};
	case actionTypes.GET_ALL_ADMIN_SUCCESS:
		return {
			...state,
			getAllError: null,
			getAllMessage: action.payload.message,
			getAllLoading: false,
			getAll: action.payload.results,
			getAllPrevUrl: action.payload.links.previous,
			getAllNextUrl: action.payload.links.next,
			getAllTotalPages: action.payload.total_pages,
			getAllPageNumber: action.payload.page_number,
		};
	case actionTypes.GET_ALL_ADMIN_FAILED:
		return {
			...state,
			getAllError: action.payload.error,
			getAllLoading: false,
		};
	default:
		return state;
	}
};

export default allBlogAdmin;
