import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga'
import 'boxicons';
import './App.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Routes from './navigations/routes';
import BlogRoutes from './navigations/BlogRoutes';

import Footer from './components/customer/Footer';
import Header from './components/customer/Header';
import ScrollToTop from './hooks/ScrollToTop'

function App() {
	const [isDashboard, setIsDashboard] = useState(false);
	const [isRegd, setIsRegd] = useState(false);

	useEffect(() => {
		if (
			window.location.href.includes('admin')
			|| window.location.href.includes('blogger')
			|| window.location.href.includes('blogin')
		) {
			setIsDashboard(true);
		}

		if (window.location.href.includes('register')) {
			setIsRegd(true)
		}

		ReactGA.initialize(process.env.REACT_APP_BLOG_TRACKING_ID, { standardImplementation: true })
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);

	return (
		<div className="App">
			{(isDashboard || isRegd) ? (
				<>
					<ScrollToTop />
					<BlogRoutes />
				</>
			) : (
				<main>
					<ScrollToTop />
					<Header />
					<Routes />
					<Footer />
				</main>
			)}
		</div>
	);
}

export default App;
