/* eslint-disable no-unused-vars */
import React from 'react';

import { AiOutlineClockCircle } from 'react-icons/ai';

import { Link } from 'react-router-dom';

import moment from 'moment';
import classes from './postbox.module.scss';

const postBox = (props) => {
	const { id, data, containerStyles } = props;

	const formatDate = (date) => {
		const now = moment(new Date()); // todays date
		const end = moment(date); // another date
		const duration = moment.duration(now.diff(end));
		const days = duration.asDays();
		const hours = duration.asHours();

		if (days < 1) {
			return `${hours.toFixed(0)} hours ago`;
		}
		return `${days.toFixed(0)} days ago`;

		// return moment(date).format("MMMM Do, YYYY")
	};

	const postImageUrl = data.image;

	return (
		<Link
			to={`/post/${data.id}`}
			style={{
				textDecoration: 'none',
				color: 'unset',
			}}
		>
			<div className={classes.article}
				style={containerStyles}
			>
				<div style={{
					height: 250,
					overflow: 'hidden',
					borderRadius: '10px 10px 0 0'
				}}>
					<img
						style={{
							width: '100%',
							height: '100%',
							objectFit: 'fit'
						}}

						src={postImageUrl} />
				</div>
				<div className={classes['article-details-wrap']}>
					<div className={classes.heading}>
						{data.title}
					</div>
					<div
						style={{
							width: '100%',
							marginTop: 2,
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<AiOutlineClockCircle color="#EA2604" height='0.5em'/>
						<span className={classes['article-time']}>
							{formatDate(data.approve_date)}
						</span>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default postBox;
