/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import draftToHtml from 'draftjs-to-html';
import DOMPurify from 'dompurify';
import { IoMdPerson } from 'react-icons/io';
import axios from 'axios';
import approveBlog from '../../../../store/actions/admin/approveBlog.action';
import rejectBlog from '../../../../store/actions/admin/rejectBlog.action';
import unpublishBlog from '../../../../store/actions/admin/unpublish.action';
import deleteBlog from '../../../../store/actions/admin/deleteBlog.action';
import styles from './Post.module.scss'

import Nav from '../../../../components/admin/Nav/Nav';
import Sidemenu from '../../../../components/admin/Sidemenu/Sidemenu';
import Comments from '../Comments/Comments';
import Button from '../../../../components/Button/button.jsx';
import ActionModal from '../../../../components/ActionModal/actionModal.jsx';

import bck from '../../../../assets/admin/back.png';

const Post = (props) => {
	const dispatch = useDispatch()
	const history = useHistory()

	const [blog, setBlog] = useState({});
	const [comment, setComment] = useState([]);
	const [toggle, setToggle] = useState(false);
	const [openRemark, setOpenRemark] = useState(false);
	const [reviewModal, setReviewModal] = useState(false)
	const [unpublishModal, setUnpublishModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [rejectId, setRejectId] = useState(null)
	const [remark, setRemark] = useState('')

	const { approveLoading } = useSelector(({ approveBlogReducer }) => ({
		approveLoading: approveBlogReducer.loading,
	}))

	const { rejectLoading } = useSelector(({ rejectBlogReducer }) => ({
		rejectLoading: rejectBlogReducer.loading
	}))

	const { unpublishLoading } = useSelector(({ unpublishBlogReducer }) => ({
		unpublishLoading: unpublishBlogReducer.loading
	}))

	const { deleteLoading } = useSelector(({ deleteBlogReducer }) => ({
		deleteLoading: deleteBlogReducer.loading
	}))

	const openReviewModal = () => {
		setReviewModal(!reviewModal)
	}

	const openAdminRemarkModal = () => {
		setOpenRemark(!openRemark)
	}

	const openUnpublishModal = () => {
		setUnpublishModal(!unpublishModal)
	}

	const openDeleteModal = () => {
		setDeleteModal(!deleteModal)
	}

	const createMarkup = (html) => ({
		__html: DOMPurify.sanitize(html)
	})

	useEffect(() => {
		const { location } = props
		if (location.blog) {
			const blogId = props.location.blog.post.id;

			localStorage.setItem('blogId', JSON.stringify(props.location.blog.post.id));

			const url = 'https://www.spendwise.ng/api/blog/get_blog/';

			axios
				.post(
					url,
					{ id: blogId }
				)
				.then((res) => {
					if (res.status === 200) {
						setBlog(res.data.blog_data);
						setComment(res.data.comments)
					}
				})
				.catch((err) => err);
		} else {
			const currentId = JSON.parse(localStorage.getItem('blogId'));

			const url = 'https://www.spendwise.ng/api/blog/get_blog/';

			axios
				.post(
					url,
					{ id: currentId }
				)
				.then((res) => {
					if (res.status === 200) {
						setBlog(res.data.blog_data);
						setComment(res.data.comments)
					}
				})
				.catch((err) => err);
		}
		return () => {
			localStorage.removeItem('blogId');
		};
	}, [props]);

	const approvePost = (id) => {
		dispatch(approveBlog(id, () => history.go(0)))
	}

	const rejectPost = (reason) => {
		dispatch(rejectBlog({ rejectId, reason }, () => history.go(0)))
	}

	const unpublishPost = (reason) => {
		dispatch(unpublishBlog({ rejectId, reason }, () => history.go(0)))
	}

	const deletePost = () => {
		dispatch(deleteBlog(rejectId, () => history.go(0)))
	}

	// <----converting the timestamp to normal date time---->
	const DATE_OPTIONS = {
		weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
	};

	return (
		<div>
			<Toaster containerStyle={{ fontWeight: 'bold', fontSize: 12 }}/>
			<Sidemenu />
			<Nav />
			<section className={styles['post-container']}>
				{blog && !blog.id
					? (<div className={styles.loader}>
						<box-icon name='loader-circle' animation='spin' rotate='90' size='lg' ></box-icon>
					</div>)
					: (<>
						<div className={styles['post-header']}>
							<h4 onClick={props.history.goBack}>
								<img src={bck} alt="img" />
						Back
							</h4>
						</div>

						<div className={styles.title}>
							<div className={styles.topic}>
								<h3>Title</h3>
								<h2>{blog.title}</h2>
							</div>
							<div className={styles.category}>
								<h3>Category</h3>
								<h2>{blog.category}</h2>
							</div>
							<div className={styles.category}>
								<h3>Views</h3>
								<h2>{blog.views}</h2>
							</div>
							<div className={styles.details}>
								<h6> <IoMdPerson size={15} className={styles['person-icon']} /> {blog.author_name}</h6>
								<h3>Created: {` ${new Date(blog.date_created).toLocaleDateString('en-US', DATE_OPTIONS)}`}</h3>
								{blog && blog.is_approved && <h3>Published: {` ${new Date(blog.approve_date).toLocaleDateString('en-US', DATE_OPTIONS)}`}</h3>}
							</div>

							{blog.in_review && !blog.review_needed
								&& (
									<>
										{approveLoading ? (
											<box-icon name='loader-circle' animation='spin' rotate='90' size='sm' color='white'></box-icon>
										) : (
											<>
												<ol className={styles['post-toggle']}>
													<li>
														<input
															type="checkbox"
															id={blog.id}
															onClick={() => approvePost(blog.id)}
														/>
														<label htmlFor={blog.id}>
															<span /> Approve
														</label>
													</li>
												</ol>
											</>
										)}
										<Button name='Reject' danger action={() => { openReviewModal(); setRejectId(blog.id) }}/>
									</>
								)
							}

							{blog.review_needed && blog.admins_review_remark
								&& (
									<>
										<div className={styles.reject}>Rejected</div>
										<Button name='View Review' info action={() => { openAdminRemarkModal(); setRemark(blog.admins_review_remark) }}/>
									</>
								)
							}

							<div className={styles.actions}>
								{blog.is_approved
									&&								<>
										<Button name='Delete' danger action={() => { openDeleteModal(); setRejectId(blog.id) }}/>
										<Button name='Unpublish' warning action={() => { openUnpublishModal(); setRejectId(blog.id) }}/>
									</>
								}
							</div>

						</div>

						<div className={styles['comment-and-content']}>
							<h5 onClick={() => setToggle(false)} className={!toggle ? `${styles.on}` : ''}>
						Content
							</h5>
							<h5 onClick={() => setToggle(true)} className={toggle ? `${styles.on}` : ''}>
						Comments
							</h5>
						</div>

						{!toggle ? (
							<section className={styles['main-post-section']}>
								<div>
									<h4>Image and Content</h4>
								</div>

								<hr />

								<div className={styles['main-post-image']}>
									<img src={blog.image} alt="img" id={styles.pic} />
								</div>
								<div className={styles.body}>
									<div dangerouslySetInnerHTML={createMarkup(
										draftToHtml(JSON.parse(blog.body))
									)}></div>
								</div>
							</section>
						) : (
							<Comments data={comment}/>
						)}
					</>)}
			</section>
			<ActionModal
				open={reviewModal} onClose={openReviewModal} action={rejectPost} loading={rejectLoading}/>
			<ActionModal open={openRemark} onClose={openAdminRemarkModal} review={remark} />
			<ActionModal
				open={unpublishModal}
				onClose={openUnpublishModal} action={unpublishPost} unpublish loading={unpublishLoading}/>
			<ActionModal
				open={deleteModal}
				onClose={openDeleteModal} action={deletePost} toDelete loading={deleteLoading}/>
		</div>
	);
};

Post.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object
}

Post.defaultProps = {
	location: {},
	history: {}
}

export default withRouter(Post);
