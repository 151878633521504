import {
	SUSPEND_BLOGGERS_REQUEST,
	SUSPEND_BLOGGERS_FAILURE,
	SUSPEND_BLOGGERS_SUCCESS
} from '../../actionTypes'

const initialState = {
	response: null,
	loading: false,
	error: null
}

export default function suspendBlogger(state = initialState, action) {
	switch (action.type) {
	case SUSPEND_BLOGGERS_REQUEST:
		return { ...state, loading: true };
	case SUSPEND_BLOGGERS_FAILURE:
		return { ...state, loading: false, error: action.payload };
	case SUSPEND_BLOGGERS_SUCCESS:
		return {
			...state,
			loading: false,
		};
	default:
		return state;
	}
}
