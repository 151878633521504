import Axios from 'axios'
import toast from 'react-hot-toast'
import actionCreator from '../../../utils/actionCreator'
import {
	GET_BLOGGERS_FAILURE,
	GET_BLOGGERS_REQUEST,
	GET_BLOGGERS_SUCCESS,
	SUSPEND_BLOGGERS_REQUEST,
	SUSPEND_BLOGGERS_FAILURE,
	SUSPEND_BLOGGERS_SUCCESS
} from '../../actionTypes'

export const getBloggers = () => async (dispatch) => {
	dispatch(actionCreator(GET_BLOGGERS_REQUEST))
	try {
		const { data: res } = await Axios.get('https://www.spendwise.ng/api/blog/get_all_bloggers/')
		dispatch(actionCreator(GET_BLOGGERS_SUCCESS, res))
	} catch (err) {
		dispatch(actionCreator(GET_BLOGGERS_FAILURE, 'Unable to fetch blogs at this time'));
	}
}

export const suspendAction = (id, action) => async (dispatch) => {
	dispatch(actionCreator(SUSPEND_BLOGGERS_REQUEST))
	try {
		const { data: res } = await Axios.post('https://www.spendwise.ng/api/blog/suspend_blogger/', {
			blogger_id: id
		})
		dispatch(actionCreator(SUSPEND_BLOGGERS_SUCCESS, res))
		toast.success('Blogger suspended successfully')
		action()
	} catch (err) {
		dispatch(actionCreator(SUSPEND_BLOGGERS_FAILURE, 'Unable to suspend blogger at this time'));
		toast.error('Unable to suspend blogger at this time')
	}
}
