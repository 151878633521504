import Axios from 'axios'
import toast from 'react-hot-toast'
import actionCreator from '../../utils/actionCreator'
import {
	LOGOUT_USER,
	USER_SIGN_IN_FAILURE,
	USER_SIGN_IN_REQUEST,
	USER_SIGN_IN_SUCCESS
} from '../actionTypes'

export const loginUser = (loginDetails, action) => async (dispatch) => {
	const { username, password } = loginDetails
	dispatch(actionCreator(USER_SIGN_IN_REQUEST))
	try {
		const { data: res } = await Axios.post('https://www.spendwise.ng/api/accounts/blog_in/', {
			username,
			password
		})
		if (res.agent && res.agent === 'admin') {
			// eslint-disable-next-line dot-notation
			Axios.defaults.headers.common['Authorization'] = `Token ${res.token}`
			localStorage.setItem('admin', JSON.stringify(res));
			localStorage.setItem('adminToken', JSON.stringify(res.token));
			dispatch(actionCreator(USER_SIGN_IN_SUCCESS, res))
			toast.success('Logged in Successfully')
			action()
		}
		if (res.agent && res.agent === 'blogger') {
			// eslint-disable-next-line dot-notation
			Axios.defaults.headers.common['Authorization'] = `Token ${res.token}`
			localStorage.setItem('user', JSON.stringify(res));
			localStorage.setItem('authToken', JSON.stringify(res.token));
			dispatch(actionCreator(USER_SIGN_IN_SUCCESS, res))
			toast.success('Logged in Successfully')
			action()
		}
		return null
	} catch (err) {
		dispatch(actionCreator(USER_SIGN_IN_FAILURE));
		return toast.error('Username or Password incorrect')
	}
}

export const logout = () => (dispatch) => {
	try {
		localStorage.clear()
		dispatch(actionCreator(LOGOUT_USER))
		return toast.success('You are logged out')
	} catch (err) {
		return err
	}
}
