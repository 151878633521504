import {
	GET_BLOGGER_POSTS_FAILURE,
	GET_BLOGGER_POSTS_LOADING,
	GET_BLOGGER_POSTS_SUCCESS,
	GET_PUBLISHED_FAILURE,
	GET_PUBLISHED_LOADING,
	GET_PUBLISHED_SUCCESS,
	GET_DRAFT_FAILURE,
	GET_DRAFT_LOADING,
	GET_DRAFT_SUCCESS,
	GET_REJECTED_FAILURE,
	GET_REJECTED_LOADING,
	GET_REJECTED_SUCCESS
} from '../../actionTypes'

const initialState = {
	blogs: [],
	loading: false,
	error: null,
	getNextUrl: '',
	getPrevUrl: '',
	acceptedBlogs: [],
	rejectedBlogs: [],
	draftBlogs: []
}

export const bloggerBlogsReducer = (state = initialState, action) => {
	switch (action.type) {
	case GET_BLOGGER_POSTS_LOADING:
		return { ...state, loading: true };
	case GET_BLOGGER_POSTS_FAILURE:
		return { ...state, loading: false, error: action.payload };
	case GET_BLOGGER_POSTS_SUCCESS:
		return {
			...state,
			loading: false,
			blogs: action.payload.results,
			getAllPrevUrl: action.payload.links.previous,
			getAllNextUrl: action.payload.links.next
		};
	default:
		return state;
	}
}

export const publishedBlogsReducer = (state = initialState, action) => {
	switch (action.type) {
	case GET_PUBLISHED_LOADING:
		return { ...state, loading: true };
	case GET_PUBLISHED_FAILURE:
		return { ...state, loading: false, error: action.payload };
	case GET_PUBLISHED_SUCCESS:
		return {
			...state,
			loading: false,
			getAllPrevUrl: action.payload.links.previous,
			getAllNextUrl: action.payload.links.next,
			acceptedBlogs: action.payload.results
		};
	default:
		return state;
	}
}

export const rejectedBlogReducer = (state = initialState, action) => {
	switch (action.type) {
	case GET_REJECTED_LOADING:
		return { ...state, loading: true };
	case GET_REJECTED_FAILURE:
		return { ...state, loading: false, error: action.payload };
	case GET_REJECTED_SUCCESS:
		return {
			...state,
			loading: false,
			getAllPrevUrl: action.payload.links.previous,
			getAllNextUrl: action.payload.links.next,
			rejectedBlogs: action.payload.results
		};
	default:
		return state;
	}
}

export const getDraftsReducer = (state = initialState, action) => {
	switch (action.type) {
	case GET_DRAFT_LOADING:
		return { ...state, loading: true };
	case GET_DRAFT_FAILURE:
		return { ...state, loading: false, error: action.payload };
	case GET_DRAFT_SUCCESS:
		return {
			...state,
			loading: false,
			getAllPrevUrl: action.payload.links.previous,
			getAllNextUrl: action.payload.links.next,
			draftBlogs: action.payload.results
		};
	default:
		return state;
	}
}
