import React from 'react';
import classes from './button.module.css';

const button = (props) => {
	const {
		title, clicked, styles, disable
	} = props;

	return (
		<button className={classes.button} style={styles} disabled={disable}
			onClick={clicked}
		>
			{title}
		</button>
	)
}

export default button;
