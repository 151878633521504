import Axios from 'axios'
import toast from 'react-hot-toast'
import actionCreator from '../../../utils/actionCreator'
import {
	UNPUBLISH_POSTS_FAILURE,
	UNPUBLISH_POSTS_LOADING,
	UNPUBLISH_POSTS_SUCCESS
} from '../../actionTypes'

const unpublishBlog = (data, action) => async (dispatch) => {
	const { rejectId, reason } = data
	dispatch(actionCreator(UNPUBLISH_POSTS_LOADING))
	try {
		const { data: res } = await Axios.post('https://www.spendwise.ng/api/blog/reject_unpublish_post/', {
			action: 'unpublish',
			blog_id: rejectId,
			reason
		})
		dispatch(actionCreator(UNPUBLISH_POSTS_SUCCESS, res))
		toast.success('Blog unpublished successfully')
		action()
	} catch (err) {
		dispatch(actionCreator(UNPUBLISH_POSTS_FAILURE, 'Unable to unpublish the blogPost at this time'));
		toast.error('Unable to unpublish the blogpost at this time')
	}
}

export default unpublishBlog
